import React, { useState } from "react";
import { Typography, Box, Button, Grid } from "@mui/material";

import getQuote from "../Images/getQuote.jpg";
import getQuotewebP from "../Images/getQuotewebP.webp";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import ContactModal from "./ContactModal";
import ImageComponent from "./ImageComponent";


function Contact() {
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={6}>
        <Box
          sx={{
            marginRight: "40px",
            marginLeft: "40px",
            marginTop: "40px",
            marginBottom: "40px",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            borderRadius: "20px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.4)",
          }}
        >
          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                color: "rgba(0, 0, 0, 0.7)",
                fontWeight: "600",
                marginTop: "30px",
                marginBottom: "10px",
                textAlign: "center",
              }}
            >
              Contact Us
            </Typography>
          </Box>

          <Box sx={{ marginRight: "30px", marginLeft: "30px" }}>
            <Typography
              variant="body2"
              sx={{
                color: "#86939E",
                marginTop: "10px",
                marginBottom: "10px",
                textAlign: "center",
              }}
            >
              Your satisfaction is our priority. Don't hesitate to reach out
              with any questions or concerns. We're here to provide the best
              assistance for you!
            </Typography>
          </Box>

          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
              marginTop: "20px",
              paddingBottom: "40px",
            }}
          >
            <Button
              varinat="outlined"
              sx={{
                backgroundColor: "rgba(0, 0, 0, 0.2)",
                color: "black",
                border: "none",
                textTransform: "none",
                borderRadius: "20px",
                padding: "10px 20px 10px 20px",
                fontWeight: 550,
                ":hover": {
                  backgroundColor: "rgba(0,0,0,0.3)",
                },
              }}
              onClick={handleModalOpen}
            >
              Request a Quote
            </Button>
            <ContactModal isOpen={modalOpen} onClose={handleModalClose} />
          </Box>
        </Box>
      </Grid>

      <Grid
        item
        xs={4}
        sm={1}
        justifyContent="center"
        display="flex"
        flexDirection="row"
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item zIndex="1">
            <Box sx={{ display: "block" }}>
              <a
                href={`mailto: alkanaf01@gmail.com`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                    margin: "0px 0px 10px 0px",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <EmailOutlinedIcon
                    sx={{
                      color: "white",
                      padding: "10px 0px 10px 10px",
                      fontSize: "40px",
                    }}
                  />
                  <Typography
                    sx={{ color: "white", padding: "10px 10px 10px 5px" }}
                  >
                    alkanaf01@gmail.com
                  </Typography>
                </Box>
              </a>

              <a
                href={`tel:+971 525766253`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                    margin: "0px 0px 10px 0px",
                    width: "100%",
                  }}
                >
                  <CallOutlinedIcon
                    sx={{
                      color: "white",
                      padding: "10px 0px 10px 10px",
                      fontSize: "40px",
                    }}
                  />
                  <Typography
                    sx={{ color: "white", padding: "10px 10px 10px 5px" }}
                  >
                    +971 525766253
                  </Typography>
                </Box>
              </a>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={8} sm={5}>
        <Box sx={{ height: "100%" }}>
          <ImageComponent
            webpImage={getQuotewebP}
            fallbackImage={getQuote}
            altText="Get Quote"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

export default Contact;
