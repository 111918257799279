import React, { useState, useEffect } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { auditServices } from "./AuditServicesData";
import ServiceDescriptionRenderer from "../Components/ServiceDescriptionRenderer";
import ScrollToTopButton from "../Components/ScrollToTopButton";
import {
  Container,
  Grid,
  Typography,
  Box,
  List,
  ListItem,
  Paper,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import auditService from "../Images/Audit-Service.jpg";
import auditServicewebP from "../Images/Audit-ServicewebP.webp";
import ImageComponent from "../Components/ImageComponent";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import ContactModal from "../Components/ContactModal";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "auto",
    width: "100%",
    margin: "100px 0px 5px 0px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  imageContainer: {
    height: "400px",
    position: "relative",
  },

  imageOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%", // Set height to 100% to cover the entire image
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Brown color with opacity
    zIndex: 1, // Ensure it's above the image
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    padding: "10px", // Add padding for better readability
    boxSizing: "border-box", // Include padding in total width/height
  },
  overlayText: {
    fontWeight: "bold",
    color: "white",
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
    textAlign: "center",
    paddingBottom: "30px",
  },
  overlayParagraph: {
    fontSize: "1em",
    color: "white",
    textAlign: "center",
    paddingLeft: "30px",
    paddingRight: "30px",
  },
  listItem: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
    borderBottom: "1px solid rgba(196, 197, 219, 255)",
  },
  activeListItem: {
    backgroundColor: "#2196F3",
    color: "#fff",
    borderBottom: "1px solid rgba(196, 197, 219, 255)",
  },
  content: {
    marginBottom: "10px",
    padding: "20px",
  },
  contact: {
    backgroundColor: "#1976d2",
    marginTop: "20px",
    marginBottom: "20px",
    padding: "20px",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  contactTitle: {
    color: "white",
    marginBottom: "10px",
  },
  callIcon: {
    color: "white",
  },
}));

const AuditService = () => {
  const classes = useStyles();
  const { search } = useLocation();

  const queryParams = new URLSearchParams(search);
  const indexFromUrl = queryParams.get("index");

  const [activeSection, setActiveSection] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [sectionsRefs, setSectionsRefs] = useState([]);

  useEffect(() => {
    setSectionsRefs(Array(auditServices.length).fill(React.createRef()));
  }, []);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    if (indexFromUrl) {
      scrollToSection(parseInt(indexFromUrl));
    }
  }, [indexFromUrl]);

  const handleScroll = () => {
    // Calculate the active section based on scroll position
    // You might need to adjust these values based on your layout and styling
    const scrollPosition = window.scrollY + window.innerHeight / 2;
    const sections = document.querySelectorAll(".content-section");

    sections.forEach((section, index) => {
      const rect = section.getBoundingClientRect();
      if (rect.top <= scrollPosition && rect.bottom >= scrollPosition) {
        setActiveSection(index);
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToSection = (index) => {
    const sections = document.querySelectorAll(".content-section");
    const section = sections[index];
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <div className={classes.container}>
      <Container maxWidth="100%" disableGutters>
        <Box sx={{ position: "relative" }}>
          <Box className={classes.imageContainer}>
            <ImageComponent
              webpImage={auditServicewebP}
              fallbackImage={auditService}
              altText="Auditing Services"
              style={{
                objectFit: "cover",
                height: "100%",
                width: "100%",
              }}
            ></ImageComponent>
            <Box className={classes.imageOverlay}>
              <Typography variant="h1" className={classes.overlayText}>
                AUDITING SERVICES IN DUBAI
              </Typography>
              <Typography className={classes.overlayParagraph}>
                At AlKanaf Accountants, we offer premier auditing services in
                Dubai, ensuring meticulous examination and validation of
                financial information for businesses. Our seasoned auditors
                bring a wealth of expertise, adhering to international
                standards, to provide comprehensive assurance and support to our
                clients. Trust us for a thorough and reliable audit that goes
                beyond compliance, offering valuable insights for your
                business's financial health and transparency.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Grid
          container
          sx={{
            marginTop: "10px",
          }}
        >
          <Grid item sm={1} xs={0}></Grid>
          <Grid item xs={12} sm={3}>
            <Box>
              <Typography
                variant="h3"
                sx={{ fontWeight: 600, paddingLeft: "16px" }}
              >
                Services
              </Typography>
            </Box>
            <List>
              {auditServices.map((service, index) => (
                <ListItem
                  key={index}
                  ref={(el) => (sectionsRefs[index] = el)}
                  className={`${classes.listItem} ${
                    activeSection === index && classes.activeListItem
                  }`}
                  onClick={() => scrollToSection(index)}
                >
                  <KeyboardArrowRightIcon sx={{ color: "#1976d2" }} />
                  {service.title}
                </ListItem>
              ))}
            </List>
            <Box className={classes.contact}>
              <Box className={classes.contactTitle}>
                <Typography variant="h4">Let's Talk</Typography>
              </Box>
              <a
                href={`tel:+971 525766253`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Box sx={{ display: "flex", marginBottom: "10px" }}>
                  <CallOutlinedIcon className={classes.callIcon} />
                  <Typography
                    variant="body1"
                    sx={{ marginLeft: "5px", color: "white" }}
                  >
                    +971 525766253
                  </Typography>
                </Box>
              </a>
              <a
                href={`mailto: alkanaf01@gmail.com`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Box sx={{ display: "flex" }}>
                  <EmailOutlinedIcon className={classes.callIcon} />
                  <Typography
                    variant="body1"
                    sx={{ marginLeft: "5px", color: "white" }}
                  >
                    alkanaf01@gmail.com
                  </Typography>
                </Box>
              </a>
              <Box>
                <Button
                  variant="contained"
                  sx={{
                    margin: "10px",
                    padding: "20px",
                    border: " 1px solid white",
                  }}
                  onClick={handleModalOpen}
                >
                  Get a Free Quote
                </Button>
                <ContactModal isOpen={modalOpen} onClose={handleModalClose} />
              </Box>
            </Box>
          </Grid>

          <Grid item sm={1} xs={0}></Grid>
          <Grid item xs={10} sm={6} className={classes.rightColumn}>
            {auditServices.map((service, index) => (
              <Paper
                key={index}
                elevation={0}
                id={`section-${index}`}
                className="content-section"
                sx={{ scrollMarginTop: "100px" }}
              >
                <Box className={classes.content}>
                  <Typography
                    variant="h3"
                    sx={{ marginBottom: "10px", fontWeight: 600 }}
                  >
                    {service.title}
                  </Typography>
                  <ServiceDescriptionRenderer
                    description={service.description}
                  />
                </Box>
              </Paper>
            ))}
          </Grid>
          <Grid item sm={1} xs={2}>
            <ScrollToTopButton
              style={{
                margin: "0px 0px 0px 0px",
                padding: "0px 0px 0px 0px",
                minWidth: "15px",
                borderRadius: "50%",
                height: "40px",
                width: "40px",
                position: "fixed",
                bottom: "40px",
                justifyContent: "center",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default AuditService;
