import React from "react";
import { Container, Grid, Typography, Button, Box } from "@mui/material";
import error from "../Images/error.jpg";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <Container maxWidth="100%" spacing={4}>
      <Grid container>
        <Grid item sm={0} xs={0}></Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="h2"
            sx={{
              color: "black",
              fontWeight: "600",
              margin: "50px 0px 20px 0px",
            }}
          >
            Oops!
          </Typography>

          <Typography
            variant="h3"
            sx={{
              color: "black",
              fontWeight: "550",
              margin: "20px 0px 20px 0px",
            }}
          >
            We can't seem to find the page you're looking for.
          </Typography>

          <Typography
            variant="h4"
            sx={{
              color: "black",
              fontWeight: "550",
              margin: "20px 0px 20px 0px",
            }}
          >
            Possible Reasons:
          </Typography>

          <Typography
            variant="body1"
            sx={{
              color: "black",
              fontWeight: "550",
              margin: "20px 0px 20px 0px",
            }}
          >
            1. The address may have been typed incorrectly.
          </Typography>

          <Typography
            variant="body1"
            sx={{
              color: "black",
              fontWeight: "550",
              margin: "20px 0px 20px 0px",
            }}
          >
            2. It may be broken or outdated link.
          </Typography>

          <Button
            variant="contained"
            component={Link}
            to="/"
            sx={{
              borderRadius: "20px",
              margin: "10px 10px 10px 10px",
              padding: "10px 20px 10px 20px",
            }}
          >
            Go Back Home
          </Button>
        </Grid>

        <Grid item xs={0} sm={0}></Grid>
        <Grid item xs={12} sm={6} sx={{ marginTop: "10px" }}>
          <Box>
            <img
              src={error}
              alt="Error"
              style={{
                width: "600px",
                height: "600px",
                objectFit: "cover",
              }}
            ></img>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default NotFoundPage;
