import React from "react";
import {
  Typography,
  Box,
  Container,
  Grid,
} from "@mui/material";
import ServiceCards from "./ServiceCards";
import { services } from "../servicesData";



function Services() {
  return (
    <Container
      disableGutters
      sx={{ paddingLeft: "20px", paddingRight: "20px", marginBottom: "20x" }}
    >
      <Box
        mt={4}
        sx={{
          textAlign: "center",
          paddingBottom: "10px",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            marginBottom: "15px",
            color: "black",
            borderBottom: "2px solid #023C6C",
            display: "inline-block",
            paddingBottom: "10px",
            fontWeight: "700",
          }}
        >
          Your Gateway to Financial Excellence Through Our Service
        </Typography>
      </Box>

      <Grid
        container
        spacing={2}
        justifyContent="center"
        sx={{ marginTop: "10px", marginBottom:"40px" }}
      >
        {services.map((service, index) => (
          <Grid key={index} item xs={12} sm={4} display="flex">
            <ServiceCards key={index} {...service} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default Services;
