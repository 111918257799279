import React from "react";
import { Typography, Box, Container, Avatar, Grid } from "@mui/material";
import experienceIcon from "../Images/experienceIcon-Colored.png";
import comprehensiveSolutionsIcon from "../Images/comprehensiveServicesIcon-Colored.png";
import costEffectiveIcon from "../Images/costEffectiveIcon.png";
import clientCentricIcon from "../Images/clientCentric.png";

function WhyChooseUs() {
  return (
    <Container
      disableGutters
      sx={{ paddingLeft: "20px", paddingRight: "20px", marginBottom: "60px" }}
    >
      <Box
        mt={4}
        sx={{
          textAlign: "center",
          paddingBottom: "10px",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            marginBottom: "15px",
            color: "black",
            borderBottom: "2px solid #023C6C",
            display: "inline-block",
            paddingBottom: "10px",
            fontWeight: "700",
          }}
        >
          Why Choose ALKANAF?
        </Typography>
      </Box>

      <Grid
        container
        spacing={2}
        justifyContent="center"
        sx={{ marginTop: "10px" }}
      >
        {/* First Row */}
        <Grid item xs={12} sm={6}>
          <Box sx={{ display: "flex", paddingRight: "20px" }}>
            <Box>
              <Avatar
                src={experienceIcon}
                alt="Experience"
                sx={{ width: "70px", height: "70px" }}
              />
            </Box>
            <Box>
              <Typography
                variant="h5"
                sx={{
                  color: "black",
                  paddingTop: "10px",
                  paddingLeft: "24px",
                  fontWeight: 600,
                }}
              >
                Competent Professionals and Expertise
              </Typography>

              <Typography
                variant="body2"
                sx={{
                  color: "black",
                  paddingTop: "10px",
                  paddingLeft: "24px",
                  fontWeight: 550,
                }}
              >
                Rely on our team of highly skilled professionals, possessing
                seasoned expertise in audit, tax, and accounting. Their
                proficiency has been finely honed through years of hands-on
                experience and in-depth industry knowledge.
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box sx={{ display: "flex", paddingRight: "20px" }}>
            <Box>
              <Avatar
                src={comprehensiveSolutionsIcon}
                alt="Experience"
                sx={{ width: "70px", height: "70px" }}
              />
            </Box>
            <Box>
              <Typography
                variant="h5"
                sx={{
                  color: "black",
                  paddingTop: "10px",
                  paddingLeft: "24px",
                  fontWeight: 600,
                }}
              >
                Comprehensive Service Offering
              </Typography>

              <Typography
                variant="body2"
                sx={{
                  color: "black",
                  paddingTop: "10px",
                  paddingLeft: "24px",
                  fontWeight: 550,
                }}
              >
                Consolidate your financial needs under one roof. Our firm offers
                a full suite of services, including audit, tax, and accounting,
                providing a holistic approach to managing your financial
                affairs.
              </Typography>
            </Box>
          </Box>
        </Grid>

        {/* Second Row */}
        <Grid item xs={12} sm={6}>
          <Box sx={{ display: "flex", paddingRight: "20px" }}>
            <Box>
              <Avatar
                src={costEffectiveIcon}
                alt="Experience"
                sx={{ width: "70px", height: "70px" }}
              />
            </Box>
            <Box>
              <Typography
                variant="h5"
                sx={{
                  color: "black",
                  paddingTop: "10px",
                  paddingLeft: "24px",
                  fontWeight: 600,
                }}
              >
                Competitive Pricing
              </Typography>

              <Typography
                variant="body2"
                sx={{
                  color: "black",
                  paddingTop: "10px",
                  paddingLeft: "24px",
                  fontWeight: 550,
                }}
              >
                Enjoy cost-effective financial services without compromising on
                quality. Our transparent and competitive pricing ensures that
                you receive exceptional value for your investment.
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box sx={{ display: "flex", paddingRight: "20px" }}>
            <Box>
              <Avatar
                src={clientCentricIcon}
                alt="Experience"
                sx={{ width: "70px", height: "70px" }}
              />
            </Box>
            <Box>
              <Typography
                variant="h5"
                sx={{
                  color: "black",
                  paddingTop: "10px",
                  paddingLeft: "24px",
                  fontWeight: 600,
                }}
              >
                Client-Centric Approach
              </Typography>

              <Typography
                variant="body2"
                sx={{
                  color: "black",
                  paddingTop: "10px",
                  paddingLeft: "24px",
                  fontWeight: 550,
                }}
              >
                Your satisfaction is our priority. We take the time to
                understand your business, industry, and goals, ensuring that our
                services align with your unique requirements and contribute to
                your success.
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default WhyChooseUs;
