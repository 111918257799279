import React from "react";
import { Typography, Box, Grid } from "@mui/material";
import introImage1 from "../Images/home-intro1.jpg";
import introImage2 from "../Images/home-intro2.jpg";
import introImage1webP from "../Images/home-intro1webP.webp";
import introImage2webP from "../Images/home-intro2webP.webp";
import ImageComponent from "./ImageComponent";

function Introduction() {
  return (
    <Box sx={{ marginTop: "80px", position: "relative" }}>
      <Grid container>
        <Grid item sm={6} xs={12}>
          <ImageComponent
            webpImage={introImage1webP}
            fallbackImage={introImage1}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              minHeight: "350px",
            }}
            altText="HomeImage1"
          ></ImageComponent>
        </Grid>

        <Grid item sm={6} xs={12}>
          <ImageComponent
            webpImage={introImage2webP}
            fallbackImage={introImage2}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              minHeight: "350px",
            }}
            altText="HomeImage2"
          ></ImageComponent>
        </Grid>
      </Grid>

      {/* Overlay for small screens */}
      <Grid
        item
        xs={12}
        sm={6}
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
          //display: { sm: "block", xs: "block" },
          width: "90%",
          maxWidth: "450px", // Set your maximum height here
          maxHeight: "90%", // Set your maximum height here
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          minHeight:"50%",
          minWidth:"50%",
        }}
      >
        <Typography
          variant="h2"
          sx={{ marginBottom: "10px", marginTop: "30px", color: "white", paddingLeft:"50px", paddingRight:"50px" }}
        >
          Assuring Trust, Ensuring Accuracy
        </Typography>

        <Typography
          variant="body1"
          sx={{
            marginBottom: "30px",
            marginTop: "30px",
            color: "white",
            paddingLeft: "30px",
            paddingRight: "30px",
          }}
        >
          Welcome to AlKanaf, your trusted financial consultancy in Dubai.
          Specializing in audit, accounting, and tax advisory services, we offer
          budget-friendly solutions without compromising quality. Our dedicated
          team combines local insights with global expertise to empower
          businesses of all sizes. Discover the AlKanaf difference — where
          trust, accuracy, and budget-friendly solutions converge for your
          success.
        </Typography>
      </Grid>
    </Box>
  );
}

export default Introduction;
