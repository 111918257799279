import VATreturn from "../src/Images/VAT-return.jpg";
import VATfiling from "../src/Images/VAT-filing.jpg";
import TaxRegistration from "../src/Images/Tax-Registration.jpg";
import ExternalInternalAudit from "../src/Images/audit.jpg";
import Costaudit from "../src/Images/Cost-audit.jpg";
import AccountingBookkeeping from "../src/Images/Accounting&Bookkeeping.jpg";

import VATreturnwebP from "../src/Images/VAT-returnwebP.webp";
import VATfilingwebP from "../src/Images/VAT-filingwebP.webp";
import TaxRegistrationwebP from "../src/Images/Tax-RegistrationwebP.webp";
import ExternalInternalAuditwebP from "../src/Images/auditwebP.webp";
import CostauditwebP from "../src/Images/Cost-auditwebP.webp";
import AccountingBookkeepingwebP from "../src/Images/Accounting&BookkeepingwebP.webp";

export const services = [
  {
    id: 1,
    title: "Accounting & Bookkeeping Solutions",
    image: AccountingBookkeeping,
    webPImage: AccountingBookkeepingwebP,
    description:
      "We specialize in seamless accounting and bookkeeping services, ensuring your financial records are precise and organized for optimal business success.",
    path: "/accounting?index=0",
  },

  {
    id: 2,
    title: "VAT Return",
    image: VATreturn,
    webPImage: VATreturnwebP,
    description:
      " We simplify your financial compliance with our VAT Return services, ensuring accuracy and timely submissions for peace of mind in taxation matters.",
    path: "/tax?index=1",
  },

  {
    id: 3,
    title: "VAT Filing",
    image: VATfiling,
    webPImage: VATfilingwebP,
    description:
      "We streamline the complexities of VAT filing, ensuring efficient and accurate submission processes to meet regulatory requirements seamlessly.",
    path: "/tax?index=0",
  },

  {
    id: 4,
    title: "Internal & External Audit",
    image: ExternalInternalAudit,
    webPImage: ExternalInternalAuditwebP,
    description:
      "We excel in in-depth external and internal audits, offering comprehensive insights to boost your organization's financial integrity and operational efficiency.",
    path: "/audit?index=0",
  },

  {
    id: 5,
    title: "Cost Audit",
    image: Costaudit,
    webPImage: CostauditwebP,
    description:
      "We specialize in cost audit services, meticulously examining financial processes to optimize efficiency and ensure cost-effectiveness, fostering sustainable business practices.",
    path: "/audit?index=2",
  },

  {
    id: 6,
    title: "Corporate Tax",
    image: TaxRegistration,
    webPImage: TaxRegistrationwebP,
    description:
      "We facilitate seamless Corporate Tax Registration, ensuring your business compliance with tax regulations for a smooth and legitimate financial operation.",
    path: "/tax?index=2",
  },
];
