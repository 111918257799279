import React from "react";
import { Container, Grid, Typography, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import aboutusBg from "../Images/aboutus.jpg";
import aboutusBgwebP from "../Images/aboutuswebP.webp";
import { createTheme } from "@mui/material/styles";
import mission from "../Images/Mission.jpg";
import missionwebP from "../Images/MissionwebP.webp";
import vision from "../Images/vision.jpg";
import ImageComponent from "../Components/ImageComponent";
import ceoImage from "../Images/ceo.jpg";
import ceoImagewebP from "../Images/ceowebP.webp";
import { Hidden } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "-webkit-fill-available",
    width: "100%",
    margin: "70px 0px 5px 0px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  imageContainer: {
    marginTop: "30px",
    height: "400px",
    position: "relative",
  },

  imageOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%", // Set height to 100% to cover the entire image
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Brown color with opacity
    zIndex: 1, // Ensure it's above the image
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    padding: "10px", // Add padding for better readability
    boxSizing: "border-box", // Include padding in total width/height
  },
  overlayText: {
    fontWeight: "bold",
    color: "white",
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
    textAlign: "center",
    paddingBottom: "30px",
  },
  overlayParagraph: {
    fontSize: "1em",
    color: "white",
    textAlign: "center",
    paddingLeft: "30px",
    paddingRight: "30px",
  },

  leftColumn: {
    // background:
    //   "linear-gradient(181deg, rgb(2, 0, 97) 15%, rgb(97, 149, 219) 158.5%)",
    background: "#533ac1",
    height: "100%",
  },
  rightColumn: {
    background: "#f5f5f7",
    height: "100%",
  },
  Leftbox: {
    flex: 1,
    margin: "20px 0px 40px 20px",
    boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.5)",
    backgroundColor: "rgba(196,197,219,255)",
    padding: "20px 0px 20px 20px",
    height: "420px",
    minHeight: "600px",
  },

  Rightbox: {
    flex: 1,
    margin: "20px 20px 40px 0px",
    backgroundColor: "rgba(255,255,255,255)",
    padding: "20px 20px 20px 0px",
    fontWeight: 600,
    height: "420px",
    minHeight: "600px",
  },
  leftBoxImage: {
    objectFit: "cover",
    height: "100%",
    width: "100%",
  },
  heading: {
    color: "black",
    padding: "30px",
    //textAlign: "center",
  },
  description: {
    color: "black",
    padding: "30px",
  },
  outerMessageBox: {
    backgroundColor: "#533ac1",
    padding: "10px 10px 10px 10px",
    margin: "10px 10px 10px 10px",
  },

  innerMessageBox: {
    backgroundColor: "rgba(196,197,219,255)",
    display: "flex",
    padding: "10px 10px 10px 10px",
  },

  messageBox: {
    backgroundColor: "#f5f5f7",
    width: "100%",
    height: "100%",
  },
}));

function About() {
  const classes = useStyles();
  const theme = createTheme();

  const Images = [
    { id: 1, image: mission, alt: "Mission", imageWebp: missionwebP },
    { id: 2, image: vision, alt: "Vision", imageWebp: vision },
    { id: 3, image: ceoImage, alt: "Meet the CEO", imageWebp: ceoImagewebP },
  ];

  const text = [
    {
      id: 1,
      title: "Mission",
      description: `At AlKanaf, our mission is rooted in an unwavering commitment to excellence, addressing diverse client needs with tailored solutions. We prioritize fostering a growth-oriented environment for both personal and professional development. Our dedication to core values such as integrity, transparency, and professionalism guides every aspect of our firm. AlKanaf stands firm in its commitment to be a leading provider of high-quality professional services, achieved through a comprehensive, multi-disciplinary approach. We distinguish ourselves by delivering holistic solutions in accounting, taxation, and advisory services, setting benchmarks for excellence. Join us in our mission to make a meaningful impact on our clients' success.`,
    },
    {
      id: 2,
      title: "Vision",
      description: `At AlKanaf, our vision is a dynamic commitment to excellence, delivering tailored services that address diverse client needs. We foster an environment of continuous learning and growth, both personally and professionally, guided by core values such as integrity, transparency, and professionalism. Unwavering in our dedication, AlKanaf strives to be a leading provider of high-quality professional services, employing a comprehensive, multi-disciplinary approach. Our focus on innovation ensures sustainable business solutions, setting benchmarks for excellence in accounting, taxation, and advisory services. Join us in our vision to make a lasting impact on the success of our clients.`,
    },
    {
      id: 3,
      title: "Meet the CEO/Founder",
      description: `Abbas Asghar Karimi, CEO and Founder of AlKanaf, boasts a distinguished 14-year career in accounting, marked by unwavering commitment to excellence. As a Chartered Certified Accountant (ACCA) and holder of a Bachelor of Science in Applied Accounting from Oxford Brookes University, Abbas exemplifies expertise and adherence to the highest professional standards. With a background in auditing and accounting, he garnered invaluable experience at a leading big four audit firm, managing financial affairs for major multinational corporations and family groups. Abbas's notable achievements include recognition from prominent clients such as Equate Petrochemical, Scientechnic LLC, Emerson Group, AW Rostamani Group, and Sharaf DG, showcasing his proficiency in offering strategic financial guidance. As a visionary leader, Abbas is dedicated to upholding AlKanaf's core values of excellence, transparency, and client success. Join us in benefiting from Abbas's extensive expertise and visionary leadership as we navigate the complex realms of accounting, auditing, and tax consultancy at AlKanaf.`,
    },
  ];

  const ceoMessage = {
    id: 4,
    title: "Message from the CEO",
    description:
      "Welcome to AlKanaf, your gateway to unparalleled financial solutions. At AlKanaf, we believe in more than just numbers - we believe in the power of strategic partnerships. Our commitment to excellence, transparency, and personalized service sets us apart. Join us on this extraordinary path where personalized solutions meet unmatched excellence. Your financial triumphs are not just our goal; they're our unwavering commitment. Choose AlKanaf, where success finds its strategic partner.",
  };
  return (
    <div className={classes.container}>
      <Container maxWidth="100%" disableGutters>
        <Box sx={{ position: "relative" }}>
          <Box
            className={classes.imageContainer}
            sx={{
              [theme.breakpoints.down("sm")]: {
                minHeight: "700px",
              },
            }}
          >
            <ImageComponent
              webpImage={aboutusBgwebP}
              fallbackImage={aboutusBg}
              altText="About Us"
              style={{
                objectFit: "cover",
                height: "100%",
                width: "100%",
              }}
            ></ImageComponent>
            <Box className={classes.imageOverlay}>
              <Typography variant="h1" className={classes.overlayText}>
                About Us
              </Typography>
              <Typography className={classes.overlayParagraph}>
                AlKanaf, based in Dubai, UAE, is your trusted partner for
                Accounting, Auditing, and Tax Consultancy. Committed to
                delivering comprehensive services tailored to diverse client
                needs, we specialize in in-depth auditing, strategic tax
                planning, and efficient financial management. Acting as
                strategic allies, we devise appropriate accounting systems and
                implement effective control procedures at all organizational
                levels. Beyond traditional services, our focus on innovation
                ensures sustainable business solutions that adapt to
                ever-changing market trends. With a dedication to auditing
                excellence, tax advisory, and financial management, AlKanaf is
                here to optimize your financial position and support your
                business growth.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Grid container>
          <Hidden smDown>
            {Images.map((item, index) => (
              <React.Fragment key={index}>
                <Grid item xs={12} sm={6} className={classes.leftColumn}>
                  <Box className={classes.Leftbox}>
                    <ImageComponent
                      webpImage={item.imageWebp}
                      fallbackImage={item.image}
                      altText={item.alt}
                      style={{
                        objectFit: "cover",
                        height: "100%",
                        width: "100%",
                        objectPosition: "center 10%",
                      }}
                    ></ImageComponent>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} className={classes.rightColumn}>
                  <Box
                    className={classes.Rightbox}
                    sx={{
                      [theme.breakpoints.down("sm")]: {
                        height: "auto",
                      },
                    }}
                  >
                    <Typography variant="h3" className={classes.heading}>
                      <span
                        style={{
                          paddingBottom: "4px",
                          borderBottom: "2px solid #533ac1",
                        }}
                      >
                        {text[index].title}
                      </span>
                    </Typography>
                    <Typography variant="body2" className={classes.description}>
                      {text[index].description}
                    </Typography>
                  </Box>
                </Grid>
              </React.Fragment>
            ))}
          </Hidden>
        </Grid>

        {Images.map((image, index) => (
          <Hidden smUp key={index}>
            <Box className={classes.outerMessageBox}>
              <Box className={classes.innerMessageBox}>
                <Grid container>
                  <Grid
                    item
                    sm={3}
                    justifyContent="center"
                    alignItems="center"
                    display="flex"
                  >
                    <Box sx={{ height: "100%", width: "100%" }}>
                      <ImageComponent
                        webpImage={image.imageWebp}
                        fallbackImage={image.image}
                        altText={image.alt}
                        style={{ width: "100%", height: "100%" }}
                      ></ImageComponent>
                    </Box>
                  </Grid>

                  <Grid item sm={9}>
                    <Box className={classes.messageBox}>
                      <Typography
                        variant="h3"
                        sx={{
                          padding: "30px 20px 20px 20px",
                        }}
                      >
                        <span
                          style={{
                            paddingBottom: "4px",
                            borderBottom: "2px solid #533ac1",
                          }}
                        >
                          {text[index].title}
                        </span>
                      </Typography>

                      <Typography
                        variant="body2"
                        sx={{
                          padding: "20px 20px 20px 20px",
                        }}
                      >
                        {text[index].description}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Hidden>
        ))}

        <Box className={classes.outerMessageBox}>
          <Box className={classes.innerMessageBox}>
            <Grid container>
              {/* <Grid
                item
                sm={3}
                justifyContent="center"
                alignItems="center"
                display="flex"
              >
                <Box sx={{ height: "100%" }}>
                  <ImageComponent
                    webpImage={ceoImagewebP}
                    fallbackImage={ceoImage}
                    altText="CEO Message"
                    style={{ width: "100%", height: "100%" }}
                  ></ImageComponent>
                </Box>
              </Grid> */}

              <Grid item sm={12}>
                <Box className={classes.messageBox}>
                  <Typography
                    variant="h3"
                    sx={{
                      padding: "30px 20px 20px 20px",
                    }}
                  >
                    <span
                      style={{
                        paddingBottom: "4px",
                        borderBottom: "2px solid #533ac1",
                      }}
                    >
                      {ceoMessage.title}
                    </span>
                  </Typography>

                  <Typography
                    variant="body2"
                    sx={{
                      padding: "20px 20px 20px 20px",
                    }}
                  >
                    "{ceoMessage.description}"
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </div>
  );
}

export default About;
