import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import contact from "../Images/contact.jpg";
import contactwebP from "../Images/contactwebP.webp";
import tax from "../Images/tax.jpg";
import taxwebP from "../Images/taxwebP.webp";
import accounting from "../Images/accounting.jpg";
import accountingwebP from "../Images/accountingwebP.webp";
import auditing from "../Images/auditing.jpg";
import auditingwebP from "../Images/auditingwebP.webp";
import "../Styles/slider.css";
import { Container, Paper, Box, Button, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigate } from "react-router";
import ImageComponent from "./ImageComponent";

function Carousel() {
  const navigate = useNavigate();
  const cards = [
    {
      id: 1,
      title: "Get in Touch",
      description:
        "Discover tailored financial solutions with our expert team. For advice, consultations, or information on our services, reach out via email (alkanaf01@gmail.com) or phone (+971 525766253) for a path to financial success.",
      image: contact,
      webPImage: contactwebP,
      path: "/contact",
    },
    {
      id: 2,
      title: "Tax Services Expertise",
      description:
        "Streamline your finances with our comprehensive tax services, including VAT filing, VAT return, and corporate tax registration.",
      image: tax,
      webPImage: taxwebP,
      path: "/tax",
    },
    {
      id: 3,
      title: "Accounting Solutions",
      description:
        "Optimize financial operations with our comprehensive accounting services, including bookkeeping, IFRS implementation, payroll, and more.",
      image: accounting,
      webPImage: accountingwebP,
      path: "/accounting",
    },
    {
      id: 4,
      title: "Audit Excellence",
      description:
        "Ensure financial integrity with our auditing expertise, covering external audit, internal audit, and cost audit services.",
      image: auditing,
      webPImage: auditingwebP,
      path: "/audit",
    },
  ];
  const NextArrow = ({ onClick }) => {
    return (
      <div className="next" onClick={onClick}>
        <ChevronRightIcon
          sx={{ margin: "3px 3px 0px 3px", fontSize: "20px" }}
        />
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="prev" onClick={onClick}>
        <ChevronLeftIcon
          sx={{ margin: "3px 3px 0px 3px", fontSize: "20px" }}
        />
      </div>
    );
  };

  const handleClick = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  const [imageIndex, setImageIndex] = useState(0);

  const settings = {
    infinite: true,
    lazyLoad: false,
    speed: 300,
    slidesToShow: 3,
    centerMode: true,
    centerPadding: 0,
    arrows: true,
    dots: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,

    beforeChange: (current, next) => setImageIndex(next),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          arrows: false,
          speed: 0
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  return (
    <Container disableGutters sx={{ margin: "20px 0px 20px 0px" }}>
      <Box
        mt={4}
        sx={{
          textAlign: "center",
          paddingBottom: "10px",
          paddingLeft: "20px",
          paddingRight:"20px"
        }}
      >
        <Typography
          variant="h3"
          sx={{
            marginBottom: "15px",
            color: "black",
            borderBottom: "2px solid #023C6C",
            display: "inline-block",
            paddingBottom: "10px",
            fontWeight: "700",
            textAlign:"center"
          }}
        >
          Alkanaf Financial Hub: Unleashing Success in Accounting,
          Auditing, Tax
        </Typography>
      </Box>
      <Slider {...settings}>
        {cards.map((card, idx) => (
          <Paper
            key={idx}
            elevation={3}
            className={idx === imageIndex ? " slide activeSlide" : "slide"}
            sx={{
              backgroundColor: "#023C6C",
              textAlign: "center",
              height: "550px",
            }}
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <ImageComponent
                webpImage={card.webPImage}
                fallbackImage={card.image}
                altText={card.title}
                style={{
                  width: "100%",
                  height: "200px",
                  objectFit: "cover",
                  background: "black",
                  marginBottom: "10px",
                }}
              ></ImageComponent>
              <span
                style={{
                  fontSize: "25px",
                  marginTop: "10px",
                  marginBottom: "5px",
                  color: "white",
                  fontWeight: 900,
                  fontFamily: "sans-serif",
                }}
              >
                {card.title}
              </span>
            </Box>
            <Box>
              <p
                style={{
                  margin: "10px 20px",
                  fontSize: "14px",
                  color: "white",
                }}
              >
                {card.description}
              </p>
            </Box>
            <Box>
              <Button
                sx={{
                  marginTop: "20px",
                  padding: "10px 20px 10px 20px",
                  borderRadius: "30px",
                  backgroundColor: "#023C6C",
                  border: "2px solid white",
                  color: "white",
                  fontSize: "14px",
                  ":hover": { opacity: 0.5 },
                }}
                onClick={() => {
                  handleClick(card.path);
                }}
              >
                {card.id === 1 ? "Contact" : "Learn More"}
              </Button>
            </Box>
          </Paper>
        ))}
      </Slider>
    </Container>
  );
}

export default Carousel;
