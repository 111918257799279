export const navItems = [
  {
    id: 1,
    title: "Home",
    path: "/",
    cName: "nav-item",
    menu: false,
  },
  {
    id: 2,
    title: "About us",
    path: "/about",
    cName: "nav-item",
    menu: false,
  },
  {
    id: 3,
    title: "Accounting Services",
    path: "/accounting",
    cName: "nav-item",
    menu: true,
    services: [
      {
        id: 1,
        title: "Bookkeeping",
        path: "/accounting",
        cName: "submenu-item",
      },
      {
        id: 2,
        title: "Internal Controls",
        path: "/accounting",
        cName: "submenu-item",
      },
      {
        id: 3,
        title: "IFRS Implementation",
        path: "/accounting",
        cName: "submenu-item",
      },
      {
        id: 4,
        title: "Annual Financial Statements Preparation",
        path: "/accounting",
        cName: "submenu-item",
      },
      {
        id: 5,
        title: "Management Accounts Preparation",
        path: "/accounting",
        cName: "submenu-item",
      },
      {
        id: 6,
        title: "Budget Preparation and Monitoring",
        path: "/accounting",
        cName: "submenu-item",
      },
      {
        id: 7,
        title: "Basic Payroll Services",
        path: "/accounting",
        cName: "submenu-item",
      },
      {
        id: 8,
        title: "Inventory Management & Valuation ",
        path: "/accounting",
        cName: "submenu-item",
      },
      {
        id: 9,
        title: "Fixed Assets Register (Capital Expenditure Accounting)",
        path: "/accounting",
        cName: "submenu-item",
      },
      {
        id: 10,
        title: "Account Reconciliation",
        path: "/accounting",
        cName: "submenu-item",
      },
    ],
  },
  {
    id: 4,
    title: "Audit",
    path: "/audit",
    cName: "nav-item",
    menu: true,
    services: [
      {
        id: 1,
        title: "External Audit",
        path: "/audit",
        cName: "submenu-item",
      },
      {
        id: 2,
        title: "Internal Audit",
        path: "/audit",
        cName: "submenu-item",
      },
      {
        id: 3,
        title: "Cost Audit",
        path: "/audit",
        cName: "submenu-item",
      },
    ],
  },
  {
    id: 5,
    title: "Tax Services",
    path: "/tax",
    cName: "nav-item",
    menu: true,
    services: [
      {
        id: 1,
        title: "VAT filing",
        path: "/tax",
        cName: "submenu-item",
      },
      {
        id: 2,
        title: "VAT return",
        path: "/tax",
        cName: "submenu-item",
      },
      {
        id: 3,
        title: "Corporate Tax",
        path: "/tax",
        cName: "submenu-item",
      },
    ],
  },
  {
    id: 6,
    title: "Contact",
    path: "/contact",
    cName: "nav-item",
    menu: false,
  },
];

export const auditDropdown = [
  {
    id: 1,
    title: "External Audit",
    path: "/audit",
    cName: "submenu-item",
  },
  {
    id: 2,
    title: "Internal Audit",
    path: "/audit",
    cName: "submenu-item",
  },
  {
    id: 3,
    title: "Cost Audit",
    path: "/audit",
    cName: "submenu-item",
  },
];

export const accountingServiceDropdown = [
      {
        id: 1,
        title: "Bookkeeping",
        path: "/accounting",
        cName: "submenu-item",
      },
      {
        id: 2,
        title: "Internal Controls",
        path: "/accounting",
        cName: "submenu-item",
      },
      {
        id: 3,
        title: "IFRS Implementation",
        path: "/accounting",
        cName: "submenu-item",
      },
      {
        id: 4,
        title: "Annual Financial Statements Preparation",
        path: "/accounting",
        cName: "submenu-item",
      },
      {
        id: 5,
        title: "Management Accounts Preparation",
        path: "/accounting",
        cName: "submenu-item",
      },
      {
        id: 6,
        title: "Budget Preparation and Monitoring",
        path: "/accounting",
        cName: "submenu-item",
      },
      {
        id: 7,
        title: "Basic Payroll Services",
        path: "/accounting",
        cName: "submenu-item",
      },
      {
        id: 8,
        title: "Inventory Management & Valuation ",
        path: "/accounting",
        cName: "submenu-item",
      },
      {
        id: 9,
        title: "Fixed Assets Register (Capital Expenditure Accounting)",
        path: "/accounting",
        cName: "submenu-item",
      },
      {
        id: 10,
        title: "Account Reconciliation",
        path: "/accounting",
        cName: "submenu-item",
      },
    ];

export const taxServiceDropdown = [
  {
    id: 1,
    title: "VAT filing",
    path: "/tax",
    cName: "submenu-item",
  },
  {
    id: 2,
    title: "VAT return",
    path: "/tax",
    cName: "submenu-item",
  },
  {
    id: 3,
    title: "Corporate Tax",
    path: "/tax",
    cName: "submenu-item",
  },
];

export const ITServiceDropdown = [
  {
    id: 1,
    title: "Web/App development",
    path: "./web-app-development",
    cName: "submenu-item",
  },
  {
    id: 2,
    title: "Marketing and SEO",
    path: "./marketing-SEO",
    cName: "submenu-item",
  },
  {
    id: 3,
    title: "Web designing",
    path: "./web-designing",
    cName: "submenu-item",
  },
];
