import React from "react";

const ImageComponent = ({ webpImage, fallbackImage, altText, style }) => {
  const supportsWebp = (() => {
    try {
      return (
        document
          .createElement("canvas")
          .toDataURL("image/webp")
          .indexOf("data:image/webp") === 0
      );
    } catch (err) {
      return false;
    }
  })();

  return (
    <picture style={{width:"100%", height:"100%"}}>
      {supportsWebp && webpImage ? (
        <source srcSet={webpImage} type="image/webp" />
      ) : null}
      <img src={fallbackImage} alt={altText} style={style} />
    </picture>
  );
};

export default ImageComponent;
