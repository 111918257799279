import React from "react";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";
import CircleIcon from "@mui/icons-material/Circle";
import ListItemIcon from "@mui/material/ListItemIcon";
import CheckIcon from '@mui/icons-material/Check';
import { styled } from "@mui/system";

const ServiceDescriptionRenderer = React.memo(({ description }) => {
  return (
    <Box>
      {description.map((item, index) => {
        if (item.paragraph) {
          return (
            <Typography key={index} variant="body5" >
              {item.paragraph}
            </Typography>
          );
        } else if (item.mainHeading) {
          return (
            <Box sx={{ display: "flex", marginBottom:"10px", marginTop:"10px" }}>
              <CheckIcon
                sx={{
                  color: "#1976d2",
                  marginRight: "10px",
                  paddingBotom: "0px",
                  paddingTop:"10px",
                  fontSize: "38px",
                }}
              />
              <Typography
                key={index}
                variant="h4"
                sx={{
                  marginBottom: "10px",
                  marginTop: "10px",
                  fontWeight: 600,
                }}
              >
                {item.mainHeading}
              </Typography>
            </Box>
          );
        } else if (item.subHeading) {
          return (
            <Typography
              key={index}
              variant="h5"
              sx={{ marginBottom: "2px", fontWeight: 600 }}
            >
              {item.subHeading}
            </Typography>
          );
        } else if (item.points) {
          return (
            <List key={index}>
              {item.points.map((point, pointIndex) => (
                <ListItem key={pointIndex} sx={{alignItems:"unset"}}>
                  <ListItemIcon sx={{ minWidth: "40px" , marginTop:"5px"}}>
                    <CircleIcon sx={{ color: "black", fontSize: "8px" }} />
                  </ListItemIcon>
                  <Typography variant="body2">{point}</Typography>
                </ListItem>
              ))}
            </List>
          );
        } else if (item.quote) {
          const StyledBlockquote = styled("blockquote")({
            fontStyle: "italic",
            borderLeft: "2px solid #e0e0e0",
            paddingLeft: "16px",
            marginBottom: "10px",
          });
          return <StyledBlockquote key={index}>{item.quote}</StyledBlockquote>;
        }
        else if (item.link) {
          return (
            <Typography variant="body2" sx={{marginLeft:"55px"}}>
              <a
                href={item.link.link}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                {item.link.text}
              </a>
            </Typography>
          );
        }
        return null;
      })}
    </Box>
  );
});

export default ServiceDescriptionRenderer;
