import React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { navItems } from "./NavItems";
import { useNavigate } from "react-router-dom";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";

export default function DrawerMenu({ handleDrawerToggle }) {
  const navigate = useNavigate();
  const [dropdownStates, setDropdownStates] = useState(
    navItems.map(() => false)
  );


  const handleClick = (index) => {
    setDropdownStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = !newStates[index];
      return newStates;
    });
  };

  const handleToggle = (path, index, dropdownItem) => {
    if (dropdownItem && index >=0) {
      navigate(path+"?index=" + index)
    }
    else{
    navigate(path);
    window.scrollTo(0, 0);
    }
    handleDrawerToggle();
  };

  return (
    <Box sx={{ textAlign: "center" }}>
      <Typography
        variant="h3"
        sx={{ my: 2, fontWeight: "600" }}
        onClick={handleDrawerToggle}
      >
        ALKANAF
      </Typography>
      <Divider />
      <List>
        {navItems.map((item, index) =>
          item.menu ? (
            <React.Fragment key={index}>
              <ListItem disablePadding>
                <ListItemButton onClick={() => handleClick(index)}>
                  <ListItemText primary={item.title} />
                  {dropdownStates[index] ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
              </ListItem>
              <Collapse in={dropdownStates[index]} timeout="auto" unmountOnExit>
                {item.services.map((service, serviceIndex) => (
                  <List component="div" disablePadding key={serviceIndex}>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemText
                        primary={service.title}
                        onClick={() => {
                          handleToggle(service.path, serviceIndex, true);
                        }}
                      />
                    </ListItemButton>
                  </List>
                ))}
              </Collapse>
            </React.Fragment>
          ) : (
            <ListItem key={index} disablePadding>
              <ListItemButton
                sx={{ textAlign: "left", mx: 0 }}
                onClick={() => {
                  handleToggle(item.path, -1, false);
                }}
              >
                <ListItemText primary={item.title} />
              </ListItemButton>
            </ListItem>
          )
        )}
      </List>
    </Box>
  );
}
