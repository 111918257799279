import React from "react";
import { Outlet } from "react-router-dom";
import AppBar from "./AppBar";
import Footer from "./Footer";
const PageLayout = () => (
  <>
    <AppBar />
    <Outlet />
    <Footer />
  </>
);

export default PageLayout;
