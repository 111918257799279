import React from "react";
import {
  Typography,
  Box,
  Card,
  CardContent,
  CardMedia,
  Button
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router";


const useStyles = makeStyles((theme) => ({
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  cardMedia: {
    transition: "transform 0.8s", // Add a smooth transition effect
    "&:hover": {
      transform: "scale(1.15)", // Adjust the scale factor as needed
    },
  },
}));

function ServiceCards({ id, title, description, image, path, webPImage}) {
  const classes = useStyles();
  const navigate = useNavigate();
   const supportsWebp = (() => {
     try {
       return (
         document
           .createElement("canvas")
           .toDataURL("image/webp")
           .indexOf("data:image/webp") === 0
       );
     } catch (err) {
       return false;
     }
   })();
   
   const handleClick = (path) => {
    navigate(path);
   }

  return (
    <Box>
      <Card className={classes.card}>
        <CardMedia
          component="img"
          alt={title}
          image={supportsWebp ?  webPImage: image}
          className={classes.cardMedia}
        />
        <CardContent>
          <Typography
            variant="h5"
            sx={{
              color: "black",
              fontWeight: 600,
              paddingBottom: "10px",
            }}
          >
            {title}
          </Typography>

          <Typography
            variant="body2"
            sx={{
              color: "black",
              fontWeight: 530,
            }}
          >
            {description}
          </Typography>

          <Button
            sx={{ marginTop: "10px", borderRadius:'20px' }}
            variant="outlined"
            onClick={()=>{handleClick(path)}}
          >
            Learn More
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
}

export default ServiceCards;