import React from "react";
import {
  Typography,
  Box,
  Container,
  IconButton,
  Avatar,
  Grid,
} from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import AlKanaflogo from "../Images/Al Kanaf_website.jpg";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CopyrightOutlinedIcon from "@mui/icons-material/CopyrightOutlined";
import Contact from "./Contact";
import { accountingServiceDropdown, taxServiceDropdown, auditDropdown } from "./NavItems";
import { Link, useNavigate } from "react-router-dom";


function Footer() {
  const navigate = useNavigate();
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const handleTitleClick = (path) => {
    navigate(path)
    scrollToTop();
  }

  const socialMediaIcons = [
    {
      icon: <LinkedInIcon sx={{ color: "white" }} />,
      name: "LinkedIn",
      Url: "https://www.linkedin.com/company/alkanaf-financial-consultant-llc/",
    },
    {
      icon: <InstagramIcon sx={{ color: "white" }} />,
      name: "Instagram",
      Url: "https://www.instagram.com/alkanaf01/",
    },
    {
      icon: <FacebookIcon sx={{ color: "white" }} />,
      name: "Facebook",
      Url: "https://www.facebook.com/people/Alkanaf/61555487450514/?mibextid=ZbWKwL",
    },
    {
      icon: <TwitterIcon sx={{ color: "white" }} />,
      name: "Twitter",
      Url: "https://twitter.com/Alkanaf01",
    },
  ];

  return (
    <Container
      disableGutters
      maxWidth="100%"
      sx={{ backgroundColor: "rgba(0, 0, 0, 0.7)" }}
    >
      <Contact />
      <Box
        sx={{
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
          display: "flex",
        }}
      >
        {socialMediaIcons.map((socialMedia, index) => (
          <Box key={index} sx={{ margin: "10px 20px", textAlign: "center" }}>
            <a
              style={{ textDecoration: "none", color: "inherit" }}
              href={socialMedia.Url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton
                sx={{ border: "2px solid white", borderRadius: "50%" }}
                aria-label={socialMedia.name}
              >
                {socialMedia.icon}
              </IconButton>
            </a>
          </Box>
        ))}
      </Box>

      {/* <Box sx={{ backgroundColor: "rgba(0, 0, 0, 0.4)", width:"100%", height:"100%" }}>
        
      </Box> */}

      <Grid
        container
        spacing={0}
        sx={{
          paddingLeft: "40px",
          paddingRight: "40px",
          paddingTop: "40px",
          backgroundColor: "rgba(0, 0, 0, 0.4)",
        }}
      >
        <Grid item xs={12} sm={4} sx={{ marginBottom: "30px" }}>
          <Box sx={{ display: "flex" }}>
            <Box>
              <Avatar
                src={AlKanaflogo}
                sx={{ width: "80px", height: "80px", marginLeft: "24px" }}
              ></Avatar>
            </Box>
            <Box sx={{ display: "block" }}>
              <Typography
                variant="h3"
                sx={{
                  color: "white",
                  paddingTop: "10px",
                  paddingLeft: "24px",
                  fontWeight: 600,
                }}
              >
                ALKANAF
              </Typography>

              <Typography
                variant="body2"
                sx={{
                  color: "white",
                  paddingTop: "2px",
                  paddingLeft: "24px",
                  fontWeight: 500,
                }}
              >
                ACCOUNTING & AUDITING
              </Typography>
            </Box>
          </Box>
          <Typography
            variant="body2"
            sx={{
              color: "white",
              paddingTop: "20px",
              paddingLeft: "24px",
            }}
          >
            Welcome to AlKanaf Financial Consultancy, where expertise meets
            dedication. As industry-leading financial experts, we specialize in
            accounting, auditing, tax planning, and business advisory.
          </Typography>
        </Grid>

        <Grid item xs={12} sm={4} sx={{ marginBottom: "30px" }}>
          <Box>
            <Typography
              variant="h6"
              sx={{
                color: "white",
                paddingTop: "10px",
                paddingLeft: "24px",
                fontWeight: 600,
              }}
            >
              CONTACT DETAILS
            </Typography>
          </Box>
          <Box>
            <a
              href={`mailto: alkanaf01@gmail.com`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Box sx={{ display: "flex" }}>
                <EmailOutlinedIcon
                  sx={{ paddingLeft: "24px", color: "white", fontSize: "45px" }}
                />
                <Typography
                  variant="body1"
                  sx={{
                    color: "white",
                    paddingTop: "10px",
                    paddingLeft: "10px",
                  }}
                >
                  alkanaf01@gmail.com
                </Typography>
              </Box>
            </a>

            <a
              href={`tel:+971 525766253`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Box sx={{ display: "flex" }}>
                <CallOutlinedIcon
                  sx={{ paddingLeft: "24px", color: "white", fontSize: "45px" }}
                />
                <Typography
                  variant="body1"
                  sx={{
                    color: "white",
                    paddingTop: "10px",
                    paddingLeft: "10px",
                  }}
                >
                  +971 525766253
                </Typography>
              </Box>
            </a>

            <Box sx={{ display: "flex" }}>
              <LocationOnOutlinedIcon
                sx={{ paddingLeft: "24px", color: "white", fontSize: "45px" }}
              />
              <Typography
                variant="body1"
                sx={{
                  color: "white",
                  paddingTop: "10px",
                  paddingLeft: "10px",
                }}
              >
                Dubai, United Arab Emirates
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={4} sx={{ marginBottom: "30px" }}>
          <Box>
            <Link
              onClick={scrollToTop}
              to="/about"
              style={{ textDecoration: "none" }}
            >
              <Typography
                variant="h6"
                sx={{
                  color: "white",
                  paddingTop: "10px",
                  paddingLeft: "24px",
                  fontWeight: 600,
                }}
              >
                ABOUT US
              </Typography>
            </Link>
          </Box>

          <Box>
            <Typography
              variant="body1"
              sx={{
                color: "white",
                paddingTop: "10px",
                paddingLeft: "24px",
              }}
            >
              AlKanaf, a premier Accounting, Auditing, and Tax Consultancy in
              Dubai, offers tailored financial services for sustainable growth.
              Led by CEO Abbas Asghar Karimi, a seasoned Chartered Certified
              Accountant with 14+ years of expertise, we excel in managing
              complex financial matters. Committed to excellence, we aim to be
              your top choice for comprehensive financial solutions and
              strategic guidance. At AlKanaf, we're not just consultants; we're
              your dedicated partners in financial success.
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={4} sx={{ marginBottom: "30px" }}>
          <Box>
            <Typography
              variant="h6"
              onClick={() => {
                handleTitleClick("/accounting");
              }}
              sx={{
                color: "white",
                fontWeight: 600,
                paddingTop: "10px",
                paddingLeft: "24px",
                cursor: "pointer",
              }}
            >
              ACCOUNTING & BOOKKEEPING SERVICES
            </Typography>
          </Box>

          <Box>
            {accountingServiceDropdown.map((accountingService, index) => (
              <Link
                to={"/accounting?index=" + index}
                key={index}
                style={{ textDecoration: "none" }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: "white",
                    paddingTop: "10px",
                    paddingLeft: "24px",
                  }}
                >
                  {accountingService.title}
                </Typography>
              </Link>
            ))}
          </Box>
        </Grid>

        <Grid item xs={12} sm={4} sx={{ marginBottom: "30px" }}>
          <Box>
            <Typography
              variant="h6"
              onClick={() => {
                handleTitleClick("/audit");
              }}
              sx={{
                color: "white",
                fontWeight: 600,
                paddingTop: "10px",
                paddingLeft: "24px",
                cursor: "pointer",
              }}
            >
              AUDIT SERVICES
            </Typography>
          </Box>

          <Box sx={{ paddingLeft: "24px" }}>
            {auditDropdown.map((auditService, index) => (
              <Link
                to={"/audit?index=" + index}
                key={index}
                style={{ textDecoration: "none" }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: "white",
                    paddingTop: "10px",
                  }}
                >
                  {auditService.title}
                </Typography>
              </Link>
            ))}
          </Box>
        </Grid>

        <Grid item xs={12} sm={4} sx={{ marginBottom: "30px" }}>
          <Box>
            <Typography
              variant="h6"
              onClick={() => {
                handleTitleClick("/tax");
              }}
              sx={{
                color: "white",
                fontWeight: 600,
                paddingTop: "10px",
                paddingLeft: "24px",
                cursor: "pointer",
              }}
            >
              TAX SERVICES
            </Typography>
          </Box>

          <Box sx={{ paddingLeft: "24px" }}>
            {taxServiceDropdown.map((taxService, index) => (
              <Link
                to={"/tax?index=" + index}
                key={index}
                style={{ textDecoration: "none" }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: "white",
                    paddingTop: "10px",
                  }}
                >
                  {taxService.title}
                </Typography>
              </Link>
            ))}
          </Box>
        </Grid>

        <Box sx={{ marginLeft: "auto", display: "flex" }}>
          <CopyrightOutlinedIcon
            sx={{
              color: "white",
              paddingLeft: "24px",
              fontSize: "45px",
            }}
          />
          <Typography
            variant="body1"
            sx={{
              color: "white",
              paddingTop: "10px",
              paddingLeft: "5px",
            }}
          >
            {new Date().getFullYear()} AlKanaf Financial Consultancy. All Rights
            Reserved.
          </Typography>
        </Box>
      </Grid>
    </Container>
  );
}

export default Footer;




