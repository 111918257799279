import React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router";

export default function FadeMenu({ navItem, mobileMenu }) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDropDownClose = (path, index) => {
    setAnchorEl(null);
    navigate(path+"?index="+ index);
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <Button
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        onMouseOver={handleClick}
        sx={{ color: "white" }}
        endIcon={open ? <ExpandLess /> : <ExpandMore />}
      >
        <Typography
          variant={mobileMenu ? "body1" : "body2"}
          sx={{ textTransform: mobileMenu ? "none" : "" }}
        >
          {navItem.title}
        </Typography>
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
          onMouseLeave: handleClose,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {navItem.services.map((item, index) => (
          <MenuItem
            onClick={() => {
              handleDropDownClose(navItem.path, index);
            }}
            key={index}
          >
            {item.title}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
