export const accountingServices = [
  {
    id: 1,
    title: "Bookkeeping Services",
    description: [
      {
        paragraph: `Whether you're a small startup navigating the early stages or a well-established multimillion-dollar business, maintaining a company's financial records can often feel like a daunting task. That's where we come in. At AlKanaf, we specialize in providing comprehensive bookkeeping services in Dubai, utilizing our reliable accounting software to streamline and simplify your financial management.`,
      },
      { mainHeading: `Our Offerings Include:` },
      {
        subHeading: `Documentation of Business Activities:`,
      },
      {
        points: [
          `We meticulously document all your business activities in accordance with International Financial Reporting Standards (IFRS) norms.`,
        ],
      },
      {
        subHeading: `Ledger Maintenance:`,
      },
      {
        points: [
          `Our expert team ensures accurate and up-to-date ledger maintenance for a clear overview of your financial transactions.`,
        ],
      },
      {
        subHeading: `Bank Statement Reconciliation:`,
      },
      {
        points: [
          `Trust us to handle the reconciliation of your bank statements, ensuring precision and reliability in financial reporting.`,
        ],
      },
      {
        subHeading: `Accounting Reconciliations:`,
      },
      {
        points: [
          `We perform thorough accounting reconciliations to identify and rectify any discrepancies in your financial records.`,
        ],
      },
      {
        subHeading: `Accounts Payable and Receivable Verification:`,
      },
      {
        points: [
          `Our services extend to verifying accounts payable and receivable, maintaining a balanced and transparent financial picture.`,
        ],
      },
      {
        subHeading: `Vendor Bills and Payments:`,
      },
      {
        points: [
          `Leave the hassle of managing vendor bills and payments to us, allowing you to focus on your business operations.`,
        ],
      },
      {
        subHeading: `Preparing Financial Statements:`,
      },
      {
        points: [
          `We expertly prepare comprehensive financial statements that provide a clear snapshot of your company's financial health.`,
        ],
      },
      {
        subHeading: `Expert Tax Advice:`,
      },
      {
        points: [
          `Benefit from our expert tax advice to ensure compliance and optimize your tax position.`,
        ],
      },
      {
        subHeading: `Invoicing Processing Services:`,
      },
      {
        points: [
          `Our streamlined invoicing processing services help you maintain a smooth cash flow.`,
        ],
      },
      {
        subHeading: `Proper Reviews and Updates:`,
      },
      {
        points: [
          `Regular reviews and updates ensure that your financial records are accurate, reliable, and reflective of your current business standing.`,
        ],
      },
      {
        mainHeading: `And Many More…`,
      },
      {
        paragraph: `At AlKanaf, we go beyond traditional bookkeeping. Our holistic approach covers a wide range of financial services to meet the unique needs of your business. Partner with us to simplify your financial management, allowing you to concentrate on what matters most—your business growth.`,
      },
      {
        quote: `Let AlKanaf be your trusted partner in navigating the complexities of financial record-keeping, offering you a seamless and stress-free experience.`,
      },
    ],
  },
  {
    id: 2,
    title: "Internal Controls",
    description: [
      {
        paragraph: `AlKanaf's consulting arm, backed by a seasoned team, swiftly strengthens your internal control environment. We conduct meticulous reviews and tests, identifying weaknesses and offering practical solutions to fortify your overall internal control structure.`,
      },
      {
        paragraph: `AlKanaf Consulting employs a comprehensive approach to design, build, implement, and enhance risk and control solutions. Our holistic assessment covers key components such as the control environment, risk assessment, control activities, information and communication, and monitoring, resulting in targeted risk reduction. `,
      },
      { mainHeading: `Key steps in our process:` },
      { subHeading: `Financial Analysis` },
      { points: [`Uncover insights through thorough examination.`] },
      { subHeading: `Risk Indicators` },
      { points: [`Identify and evaluate crucial indicators.`] },
      { subHeading: `Evaluation of Controls` },
      {
        points: [
          `Rigorous examination, including Sarbanes-Oxley/J-SOX compliance.`,
        ],
      },
      { subHeading: `Policy Development:` },
      {
        points: [
          `Craft policies, procedures, and key metrics for operational efficiency.`,
        ],
      },
      {
        mainHeading: `Comprehensive Services`,
      },
      {
        paragraph: `Our suite of services boosts confidence in operational effectiveness, financial reporting reliability, and legal compliance. Offerings include internal audit outsourcing, business risk analysis, fraud investigation, Sarbanes-Oxley & J-SOX compliance, information technology assurance, security risk evaluation, independent review of internal audit processes, optimization of controls, documentation of controls, formalization of responsibilities, and a tailored Small Business Essential Controls Assessment. This specialized assessment helps business owners implement a robust internal control structure as their businesses grow.`,
      },
    ],
  },
  {
    id: 3,
    title: "International Financial Reporting Standards (IFRS) Implementation",
    description: [
      {
        paragraph: `If your business operates internationally or plans to do so, our IFRS Implementation service is designed to guide you through the adoption of International Financial Reporting Standards (IFRS). We conduct a thorough assessment, provide training to your finance team, assist in system integration, develop customized accounting policies, ensure compliance, and offer ongoing support. Our experts help align your financial reporting with global standards, ensuring a seamless transition and facilitating smooth operations in the international business landscape.`,
      },
      {
        mainHeading: `Key Components of AlKanaf's IFRS Implementation Service:`,
      },
      {
        subHeading: `Thorough Assessment:`,
      },
      {
        points: [
          `We initiate the process with a thorough assessment of your current financial reporting practices and systems.`,
        ],
      },
      {
        subHeading: `Finance Team Training:`,
      },
      {
        points: [
          `Our service includes providing training to your finance team, ensuring they are well-versed in the intricacies of IFRS.`,
        ],
      },
      {
        subHeading: `System Integration Assistance:`,
      },
      {
        points: [
          `AlKanaf assists in the seamless integration of IFRS into your existing systems, minimizing disruptions to your operations.`,
        ],
      },
      {
        subHeading: `Customized Accounting Policies:`,
      },
      {
        points: [
          `We collaborate with your team to develop customized accounting policies that align with IFRS requirements and suit the unique needs of your business.`,
        ],
      },
      {
        subHeading: `Ensuring Compliance:`,
      },
      {
        points: [
          `Our experts work diligently to ensure that your financial reporting practices are fully compliant with IFRS.`,
        ],
      },
      {
        subHeading: `Ongoing Support:`,
      },
      {
        points: [
          `We provide ongoing support to address any queries, concerns, or additional needs that may arise during and after the implementation process.`,
        ],
      },
      {
        mainHeading: `Benefits of Choosing AlKanaf for IFRS Implementation`,
      },
      {
        subHeading: `Global Standards Alignment:`,
      },
      {
        points: [
          `Our experts help align your financial reporting with global standards, ensuring consistency and transparency.`,
        ],
      },
      {
        subHeading: `Seamless Transition:`,
      },
      {
        points: [
          `We facilitate a seamless transition to IFRS, minimizing disruptions and ensuring business continuity.`,
        ],
      },
      {
        subHeading: `Smooth Operations in International Business Landscape:`,
      },
      {
        points: [
          `By adopting IFRS, we facilitate smooth operations in the international business landscape, enhancing your global business capabilities.`,
        ],
      },
      {
        paragraph: `In summary, partner with AlKanaf for IFRS Implementation to navigate the complexities of international financial reporting. Our tailored services cover all aspects, from assessment to ongoing support, ensuring your business adheres to global standards and operates seamlessly on the international stage.`,
      },
    ],
  },
  {
    id: 4,
    title: "Annual Financial Statements Preparation",
    description: [
      {
        paragraph: `In the dynamic landscape of business, maintaining a formal record of financial activities is not just prudent—it's paramount. Enter the financial statement, a compass guiding you through the intricate realms of your business's fiscal landscape. Beyond being a tool for discerning your financial position and performance, it's a catalyst for informed decision-making. In the vibrant business ecosystem of the UAE, a comprehensive financial statement isn't just good practice; it's a mandatory requirement.  A meticulous financial statement comprises three pillars of financial insight:`,
      },
      {
        points: [`Balance Sheet`, `Income Statement`, `Cash flow Statement`],
      },
      {
        mainHeading: `Why Choose AlKanaf for Financial Reporting Services:`,
      },
      {
        subHeading: `Expertise in Financial Precision:`,
      },
      {
        points: [
          `AlKanaf boasts a team of seasoned experts well-versed in unraveling the complexities of financial statements. Our proficiency ensures your financial data is not just recorded but interpreted with precision.`,
        ],
      },
      {
        subHeading: `Comprehensive Financial Statements:`,
      },
      {
        points: [
          `We go beyond the basics. AlKanaf delivers complete financial statements, encompassing the Balance Sheet, Income Statement, and Cash Flow Statement, providing you with a holistic view of your business's financial health.`,
        ],
      },
      {
        subHeading: `Mandatory Compliance in UAE:`,
      },
      {
        points: [
          `In the UAE, compliance is non-negotiable. AlKanaf understands the regulatory landscape and ensures that your financial statements meet the mandatory requirements, keeping your business in good standing.`,
        ],
      },
      {
        subHeading: `Tailored Decision Support:`,
      },
      {
        points: [
          `We believe financial statements are more than records—they are strategic tools. AlKanaf transforms raw data into actionable insights, empowering your management to make decisions that foster growth and resilience.`,
        ],
      },
      {
        mainHeading: `Your Gateway to Financial Precision: AlKanaf`,
      },
      {
        paragraph: `In the realm of financial services in Dubai, AlKanaf stands as your unequivocal ally. Our expertise transforms complex financial data into clear, actionable insights, empowering your management to make decisions that transcend challenges and propel your business forward.`,
      },
      {
        quote: `Experience financial clarity and compliance with AlKanaf—a steadfast partner in elevating your financial reporting to new heights.`,
      },
    ],
  },
  {
    id: 5,
    title: "Management Accounts Preparation",
    description: [
      {
        paragraph: `At AlKanaf Consulting, we specialize in providing comprehensive management accounts preparation services for businesses in the United Arab Emirates. Managing accounting is a vital aspect of business administration, and in compliance with UAE regulations, all companies are obligated to maintain accurate accounting records.`,
      },
      { mainHeading: `Traditional Management Accounting Services` },
      {
        paragraph: `Our certified CMA (Certified Management Accountant) professionals offer a range of services, including:`,
      },
      {
        points: [
          `Development of reports containing financial and non-financial information.`,
          `Analysis and processing of information for performance indicators.`,
          `Evaluation of risks, deviations, and reconciliation with planned values.`,
          `Identification of trends and forecasting.`,
          `Recommendations for optimization of planning and internal control.`,
        ],
      },
      {
        paragraph: `We study your business thoroughly to identify profit sources and cost items, enabling you to make strategic decisions confidently.`,
      },
      {
        mainHeading: `Key Aspects of AlKanaf Consulting's Management Accounting Services:`,
      },
      { subHeading: `Business Planning:` },
      {
        points: [
          `Systematized analysis of company activities for more accurate forecasting.`,
          `Coordination of plans and actions, reallocation of resources, and revenue management.`,
        ],
      },
      { subHeading: `Professional Consulting:` },
      {
        points: [
          `Preparation of accurate financial statements for better interpretation.`,
          `Expert advice on taxation, general accounting, and compliance with regulatory authorities.`,
        ],
      },
      { subHeading: `Accounting Support:` },
      {
        points: [
          `Experienced accountants provide stability and facilitate sustainable growth.`,
          `Use of proven tools in accounting, taxation, management accounting, auditing, and more.`,
        ],
      },
      { subHeading: `Consulting Support for Management:` },
      {
        points: [
          `Providing supporting documentation for informed decision-making by top management.`,
        ],
      },
      { subHeading: `Support for Related Departments:` },
      {
        points: [
          `Clarity and accessibility of information to aid other departments in understanding financial transactions.`,
        ],
      },
      {
        subHeading: `Why Choose AlKanaf Consulting for Management Accounting:`,
      },
      {
        points: [
          `Diverse experience in management accounting, financial accounting, cost accounting, and more.`,
          `Comprehensive services, including remote options.`,
          `Talented and skilled experts offering the best solutions to business problems.`,
          `Opportunities to improve financial performance through flexible and adapted services.`,
        ],
      },
      { mainHeading: `What We Offer:` },
      {
        points: [
          `Comprehensive analysis of the company's financial condition.`,
          `Identification of opportunities for resource saving, cost reduction, and optimization.`,
          `Assistance in planning, forecasting, budgeting, and goal structuring.`,
          `Flexible conditions for management accounting and other services tailored to your business needs.`,
        ],
      },
      {
        paragraph: `Hiring our professional management accountants ensures a more cost-effective solution than employing an unskilled employee. Benefit from our unique offers and universal tools proven effective in managing businesses.`,
      },
    ],
  },
  {
    id: 6,
    title: "Budget Preparation and Monitoring",
    description: [
      {
        paragraph: `At AlKanaf, we take pride in offering premier financial planning and budgeting services in Dubai, backed by an efficient team dedicated to enhancing your business's financial health. Our comprehensive approach includes essential equipment for accurate estimation, forecasting, and trend evaluation.`,
      },
      {
        mainHeading: "Key Services and Expertise:",
      },
      {
        subHeading: "Qualified Costing Experts:",
      },
      {
        points: [
          "Our team includes qualified costing experts proficient in accurately budgeting your expenses, sales, and profitability.",
        ],
      },
      {
        subHeading: "Maximizing Profitability:",
      },
      {
        points: [
          "We go beyond budgeting by assisting in maximizing profits through strategic recommendations and changes.",
        ],
      },
      {
        subHeading: "Creative Construction of Financial Plans:",
      },
      {
        points: [
          "AlKanaf guides clients in creatively constructing financial plans, facilitating day-to-day business decisions.",
        ],
      },
      {
        subHeading: "Variance Analysis:",
      },
      {
        points: [
          "We conduct regular variance analysis, providing insights into discrepancies and suggesting ways to address unfavorable variances.",
        ],
      },
      {
        mainHeading: "Your Financial Solution in Dubai:",
      },
      {
        subHeading: "Efficient Team:",
      },
      {
        points: [
          "AlKanaf boasts an efficient team equipped to handle complex calculations and provide tailored financial solutions.",
        ],
      },
      {
        subHeading: "Elimination of Complex Calculations:",
      },
      {
        points: [
          "Say goodbye to worries about complicated calculations. Our team is here to simplify the process and save the day.",
        ],
      },
      {
        subHeading: "Tailored Financial Planning:",
      },
      {
        points: [
          "Benefit from the best financial planning tailored to your business needs, making operations smoother with AlKanaf.",
        ],
      },
      {
        paragraph: `In conclusion, entrust AlKanaf for unparalleled financial planning and budgeting services in Dubai. Our qualified experts and dedicated team are ready to empower your business with strategic financial insights and solutions, ensuring a smoother and more profitable journey.`,
      },
    ],
  },
  {
    id: 7,
    title: "Basic Payroll Services",
    description: [
      {
        paragraph: `Any business with more than one employee is obligated to have a payroll system. Timely and consistent payment not only boosts employee morale but also reflects the financial stability of the company. Additionally, it is a legal requirement for compliance with federal and state laws. While the payroll process is complex, an efficient system helps streamline and centralize the payroll method. Let's delve into the specifics of the payroll management process.`,
      },
      {
        mainHeading: `Essence of Payroll Management:`,
      },
      {
        paragraph: `The payroll management process involves the administration of an employee's financial records, encompassing salaries, wages, bonuses, deductions, and net pay. This process is crucial for ensuring accurate and timely compensation for employees.`,
      },
      {
        mainHeading: `Key Aspects of Payroll Management:`,
      },
      {
        subHeading: `Security and Confidentiality:`,
      },
      {
        points: [
          `A payroll management system promotes better security, ensuring the confidentiality of employee data. - Different levels of data access can be specified per user roles, ranging from administrators to employees.`,
        ],
      },
      {
        subHeading: `Payroll Outsourcing:`,
      },
      {
        paragraph: `Payroll Outsourcing is a strategic solution where companies entrust their payroll data to specialized organizations for efficient processing. AlKanaf Accountants offers comprehensive payroll outsourcing services, serving as your partner in maintenance, updates, and payroll processing while adhering to local regulations.`,
      },
      {
        mainHeading: `Benefits of Payroll Outsourcing with AlKanaf Accountants:`,
      },
      {
        subHeading: `Confidentiality Maintenance:`,
      },
      {
        points: [
          `Outsourcing your payroll processing to AlKanaf Accountants ensures the confidentiality of your data.`,
        ],
      },
      {
        subHeading: `Regulatory Compliance:`,
      },
      {
        points: [
          `Our services comply with local regulations, ensuring that your payroll processes meet legal requirements.`,
        ],
      },
      {
        subHeading: `Efficient Maintenance and Updates:`,
      },
      {
        points: [
          `AlKanaf Accountants takes care of the maintenance and updates of your payroll system, saving you time and effort.`,
        ],
      },
      {
        paragraph: `In conclusion, having a robust payroll management system is essential for every business. Outsourcing this critical function to AlKanaf Accountants not only ensures data security and regulatory compliance but also allows you to focus on core business activities while leaving the complexities of payroll processing in capable hands.`,
      },
    ],
  },
  {
    id: 8,
    title: "Inventory Management & Valuation ",
    description: [
      {
        paragraph: `Our Inventory Management & Valuation service is designed to help businesses effectively manage and assess the value of their inventory. Proper inventory management is crucial for maintaining accurate financial records and making informed business decisions. Our comprehensive approach ensures that your inventory is not only tracked efficiently but also valued accurately, contributing to improved financial control and strategic planning.`,
      },
      {
        mainHeading: `Key Features:`,
      },
      {
        subHeading: `Real-Time Tracking:`,
      },
      {
        points: [
          `We implement robust systems for real-time tracking of your inventory, providing up-to-date information on stock levels, movement, and trends.`,
        ],
      },
      {
        subHeading: `Accurate Valuation Methods:`,
      },
      {
        points: [
          `Utilizing industry-approved valuation methods, we ensure that your inventory is valued accurately, considering factors such as cost, market value, and potential obsolescence.`,
        ],
      },
      {
        subHeading: `Cost Optimization:`,
      },
      {
        points: [
          `Our experts analyze your inventory to identify opportunities for cost optimization, minimizing holding costs and maximizing profitability.`,
        ],
      },
      {
        subHeading: `Risk Mitigation:`,
      },
      {
        points: [
          `We conduct thorough risk assessments related to inventory, identifying potential risks such as overstocking or stockouts, and implement strategies for mitigation.`,
        ],
      },
      {
        subHeading: `Compliance with Accounting Standards:`,
      },
      {
        points: [
          `Our services adhere to accounting standards and regulations, ensuring compliance with relevant financial reporting requirements.`,
        ],
      },
      {
        subHeading: `Periodic Audits:`,
      },
      {
        points: [
          `Regular inventory audits are conducted to reconcile physical inventory with recorded data, reducing the chances of discrepancies and ensuring accuracy.`,
        ],
      },
      {
        subHeading: `Customized Reporting:`,
      },
      {
        points: [
          `Tailored reports are provided, offering insights into inventory turnover, valuation trends, and recommendations for improvement.`,
        ],
      },
      {
        mainHeading: `Benefits:`,
      },
      {
        subHeading: `Financial Transparency:`,
      },
      {
        points: [
          `Achieve greater transparency in your financial statements with accurate and well-managed inventory records.`,
        ],
      },
      {
        subHeading: `Strategic Decision-Making:`,
      },
      {
        points: [
          `Make informed strategic decisions based on real-time inventory data and valuation insights.`,
        ],
      },
      {
        subHeading: `Cost-Efficiency:`,
      },
      {
        points: [
          `Optimize costs associated with inventory holding, reducing excess and obsolete stock.`,
        ],
      },
      {
        subHeading: `Compliance Assurance:`,
      },
      {
        points: [
          `Ensure compliance with accounting standards and regulatory requirements related to inventory reporting.`,
        ],
      },
      {
        paragraph: `Our Inventory Management & Valuation service is designed to empower your business with efficient inventory control, accurate valuation, and strategic insights, ultimately contributing to your financial success.`,
      },
    ],
  },
  {
    id: 9,
    title: "Fixed Assets Register (Capital Expenditure Accounting)",
    description: [
      {
        paragraph: `At AlKanaf, we recognize that Effective Fixed Asset Management is crucial for maintaining financial stability and ensuring the long-term success of your company. Our comprehensive Fixed Asset Management services are designed to provide you with the necessary tools and insights for optimal management of your tangible assets.`,
      },
      {
        mainHeading: `Key Components of AlKanaf's Fixed Asset Management Services:`,
      },
      {
        subHeading: `Proper Tracking and Evaluation:`,
      },
      {
        points: [
          `We offer meticulous tracking and evaluation services to ensure accurate record-keeping of your fixed assets.`,
        ],
      },
      {
        subHeading: `Robust System Creation:`,
      },
      {
        points: [
          `Our dedicated team assists you in creating a robust system for managing tangible assets, covering property, equipment, and machinery.`,
        ],
      },
      {
        subHeading: `Streamlined Asset Tracking:`,
      },
      {
        points: [
          `AlKanaf helps streamline the process of asset tracking, providing you with real-time information on the value and condition of your assets.`,
        ],
      },
      {
        subHeading: `Strategic Evaluation for Optimization:`,
      },
      {
        points: [
          `Through strategic evaluation, we identify opportunities for optimization, including cost reduction, enhanced efficiency, and improved resource allocation.`,
        ],
      },
      {
        subHeading: `Empowering Your Business:`,
      },
      {
        points: [
          `Our goal is to empower your business with the necessary tools and insights, enabling you to make informed decisions regarding your fixed assets.`,
        ],
      },
      {
        subHeading: `Contributing to Financial Stability:`,
      },
      {
        points: [
          `By enhancing your Fixed Asset Management practices, we contribute to your company's financial stability and long-term success.`,
        ],
      },
      {
        mainHeading: `Why Partner with AlKanaf:`,
      },
      {
        subHeading: `Dedicated Team:`,
      },
      {
        points: [
          `Our team is committed to providing personalized and dedicated support for your Fixed Asset Management needs.`,
        ],
      },
      {
        subHeading: `Optimal Resource Allocation:`,
      },
      {
        points: [
          `We assist in identifying opportunities for improved resource allocation, ensuring efficiency in your operations.`,
        ],
      },
      {
        subHeading: `Sustainable Growth:`,
      },
      {
        points: [
          `Partnering with us paves the way for sustainable growth by optimizing your fixed asset management practices.`,
        ],
      },
      {
        paragraph: `In conclusion, collaborate with AlKanaf to elevate your Fixed Asset Management practices. Our services go beyond mere tracking, aiming to provide strategic insights that contribute to your financial stability and set the foundation for long-term success.`,
      },
    ],
  },
  {
    id: 10,
    title: "Account Reconciliation Services",
    description: [
      {
        paragraph: `At AlKanaf, we recognize the critical role that accurate financial statements play in defining the success of your business. Our specialized account reconciliation services offer a comprehensive view of your financial status, ensuring that your balance sheets are not just numbers but a true reflection of your business's health. Here's why choosing our services is the smart move for your business:`,
      },
      {
        mainHeading: `AlKanaf's Specialized Services:`,
      },
      {
        subHeading: `Bank Statement Reconciliation:`,
      },
      {
        points: [
          `Matching and reconciling business records with bank statements for a complete and accurate financial overview.`,
        ],
      },
      {
        subHeading: `Credit Card Payment Reconciliation:`,
      },
      {
        points: [
          `Ensuring seamless reconciliation of credit card payments, avoiding discrepancies in financial records.`,
        ],
      },
      {
        subHeading: `Debtors/Creditors Account Reconciliation:`,
      },
      {
        points: [
          `Our experts reconcile debtor and creditor accounts, providing clarity and transparency in financial dealings.`,
        ],
      },
      {
        subHeading: `Invoice Matching:`,
      },
      {
        points: [
          `Precise matching of invoices, directly from the bank to the company's books of accounts.`,
        ],
      },
      {
        mainHeading: `Advantages of AlKanaf's Account Reconciliation Services:`,
      },
      {
        subHeading: `Simplified Bill Payment:`,
      },
      {
        points: [
          `Our expert team streamlines the bill payment process, making it hassle-free and efficient.`,
        ],
      },
      {
        subHeading: `Accurate Accounts:`,
      },
      {
        points: [
          `Precision is our forte. We ensure that your accounts are maintained with the utmost accuracy, leaving no room for discrepancies.`,
        ],
      },
      {
        subHeading: `Error Elimination:`,
      },
      {
        points: [
          `Say goodbye to accounting errors. Our dedicated professionals meticulously reconcile accounts, minimizing the risk of mistakes.`,
        ],
      },
      {
        subHeading: `Cost Savings:`,
      },
      {
        points: [
          `Outsourcing your reconciliation needs to AlKanaf is a cost-effective solution. No need for a full-time in-house team; we offer dedicated services tailored to your requirements.`,
        ],
      },
      {
        subHeading: `Identification of Unauthorized Transactions:`,
      },
      {
        points: [
          `Our thorough reconciliation process helps identify and address any unauthorized transactions, ensuring the security of your financial data.`,
        ],
      },
      {
        mainHeading: `Why Choose AlKanaf:`,
      },
      {
        subHeading: `State-of-the-Art Accounting Software:`,
      },
      {
        points: [
          `We utilize powerful accounting software, including QuickBooks, Xero, IRIS, Peachtree, and Sage, ensuring error-free results.`,
        ],
      },
      {
        subHeading: `Professional Accountants:`,
      },
      {
        points: [
          `Our highly qualified and experienced accountants are continuously trained to stay updated with the latest industry trends and regulations.`,
        ],
      },
      {
        subHeading: `Trust and Confidentiality:`,
      },
      {
        points: [
          `Your data's security is our top priority. We enforce strict confidentiality measures, providing a secure environment for your financial information.`,
        ],
      },
      {
        subHeading: `Compliance with Laws and Regulations:`,
      },
      {
        points: [
          `We adhere to government accounting laws and regulations, organizing business records in compliance with industry standards.`,
        ],
      },
      {
        subHeading: `Cost-Effective Services:`,
      },
      {
        points: [
          `Outsourcing to AlKanaf is a cost-effective solution, saving you both time and money without compromising on the quality of reconciliation services.`,
        ],
      },
      {
        mainHeading: `Industries We Serve:`,
      },
      {
        points: [
          `Food and Beverage`,
          `Travel and Leisure`,
          `E-commerce and Retail`,
          `Insurance`,
          `Healthcare`,
          `Finance`,
          `Real Estate`,
          `Investments`,
          `Telecommunication`,
          `Non-profit Organizations`,
        ],
      },
      {
        paragraph: `Partner with AlKanaf for expert account reconciliation services, and experience the peace of mind that comes with knowing your financial statements are in capable hands. Focus on your core activities, and let us handle the intricacies of account reconciliation with unmatched expertise.`,
      },
    ],
  },
];