export const taxServices = [
  {
    id: 1,
    title: "VAT Filing",
    description: [
      {
        paragraph: `At AlKanaf Accountants, we take pride in offering comprehensive VAT Filing Services in the UAE, ensuring that our clients navigate the intricate landscape of Value Added Tax with ease. Our dedicated VAT experts bring international experience in taxation, providing tailored services to meet the specific needs of your business.`,
      },
      {
        mainHeading: `Expert VAT Advisory Services:`,
      },
      {
        points: [
          `Our VAT experts are committed to delivering the best advisory services in compliance with UAE VAT regulations.`,
          `We assess clients' business needs to provide strategic guidance on VAT implementation and compliance.`,
        ],
      },
      {
        mainHeading: `Comprehensive VAT Services:`,
      },
      {
        points: [
          `Our VAT services encompass various key aspects, including representation to the Federal Tax Authority (FTA) as a registered tax agent.`,
          `We assist in the seamless registration for VAT with the FTA, ensuring adherence to regulatory requirements.`,
        ],
      },
      {
        mainHeading: ` Implementation Support:`,
      },
      {
        points: [
          `AlKanaf Accountants supports organizations in the effective implementation of VAT within their operations.`,
          `We provide guidance on reviewing accounting transactions to ensure compliance with VAT regulations.`,
        ],
      },
      {
        mainHeading: `Monthly/Quarterly/Yearly VAT Filing:`,
      },
      {
        points: [
          `Our team handles the filing of VAT returns on a timely basis, ensuring compliance with the FTA's reporting requirements.`,
          `We streamline the process of filing monthly, quarterly, and yearly VAT returns, allowing businesses to focus on core operations.`,
        ],
      },
      {
        mainHeading: `VAT Refund Processing:`,
      },
      {
        points: [
          `AlKanaf Accountants facilitates the application for VAT refund return forms, optimizing financial outcomes for our clients.`,
        ],
      },
      {
        mainHeading: `Awareness Sessions:`,
      },
      {
        points: [
          `We conduct informative awareness sessions for client teams, ensuring that businesses stay well-informed about VAT implications and compliance.`,
        ],
      },
      {
        mainHeading: `Registered Tax Agent Expertise:`,
      },
      {
        points: [
          `As a registered tax agent in the UAE, AlKanaf Accountants brings credibility and expertise to serve the business community effectively.`,
          `Article 14 of Federal Law No. 7 of 2017 and Article 10 of the executive regulations govern the rights and obligations of tax agents, positioning AlKanaf Accountants as a trusted partner.`,
        ],
      },
      {
        mainHeading: `Official Recognition by FTA:`,
      },
      {
        points: [
          `We are proud to be listed as a registered tax agent on the official website of the Federal Tax Authority, showcasing our commitment to excellence and adherence to regulatory standards.`,
        ],
      },
      {
        paragraph: `Choose AlKanaf Accountants for VAT Filing Services in the UAE, where expertise meets efficiency, and compliance is seamlessly integrated into your business processes.`,
      },
    ],
  },
  {
    id: 2,
    title: `VAT Return`,
    description: [
      {
        paragraph: `AlKanaf Accountants offers specialized VAT Return Services designed to streamline the compliance process for businesses in the UAE. Our dedicated team of VAT experts, with diverse industry experience, ensures that your VAT returns are accurate, timely, and in full compliance with the regulations set by the Federal Tax Authority (FTA).`,
      },
      {
        mainHeading: `Precise VAT Return Filings:`,
      },
      {
        points: [
          `Our VAT experts meticulously handle the preparation and filing of VAT returns, minimizing the risk of errors and ensuring accuracy.`,
        ],
      },
      {
        mainHeading: `Timely Submission:`,
      },
      {
        points: [
          `We prioritize timely submission of VAT returns, helping businesses avoid penalties and maintain a seamless relationship with the FTA.`,
        ],
      },
      {
        mainHeading: `Compliance Review:`,
      },
      {
        points: [
          `AlKanaf Accountants conducts thorough reviews of accounting transactions to ensure they align with VAT regulations, mitigating compliance risks.`,
        ],
      },
      {
        mainHeading: `Monthly/Quarterly/Yearly Reporting:`,
      },
      {
        points: [
          `Our services cover the spectrum of reporting frequencies, including monthly, quarterly, and yearly VAT returns, adapting to the unique needs of your business.`,
        ],
      },
      {
        mainHeading: ` VAT Refund Assistance:`,
      },
      {
        points: [
          `We assist in the application for VAT refund return forms, optimizing financial outcomes for businesses seeking refunds.`,
        ],
      },
      {
        mainHeading: `Team Awareness Sessions:`,
      },
      {
        points: [
          `Our team conducts informative awareness sessions for client teams, keeping them abreast of VAT regulations and implications.`,
        ],
      },
      {
        mainHeading: `Registered Tax Agent Expertise:`,
      },
      {
        points: [
          `Leveraging our status as a registered tax agent in the UAE, AlKanaf Accountants ensures a comprehensive understanding of the rights and obligations outlined in the legislation.`,
        ],
      },
      {
        mainHeading: `Official Recognition by FTA:`,
      },
      {
        points: [
          `As listed on the official website of the Federal Tax Authority, our recognition as a registered tax agent underlines our commitment to excellence and adherence to regulatory standards.`,
        ],
      },
      {
        paragraph: `Choose AlKanaf Accountants for VAT Return Services in the UAE, where precision, timeliness, and compliance converge to support the financial health of your business.`,
      },
    ],
  },
  {
    id: 3,
    title: `Corporate Tax`,
    description: [
      {
        paragraph: `In January 2022, the Ministry of Finance announced a significant development - the introduction of federal Corporate Tax (CT) on the net profits of businesses operating in the United Arab Emirates. Corporate tax is a direct tax levied on the net income or profit of corporations and entities engaged in business activities.`,
      },
      {
        mainHeading: `Key Announcements and Timeline:`,
      },
      {
        points: [
          `The introduction of Corporate Tax is outlined in the UAE Federal Decree-Law No. 47 of 2022 on the taxation of corporations and businesses.`,
          `Businesses are slated to become subject to UAE Corporate Tax starting from the commencement of their first financial year on or after June 1, 2023.`,
        ],
      },
      {
        mainHeading: `Applicability of Corporate Tax:`,
      },
      {
        points: [
          `Corporate Tax will be applicable to all businesses and individuals conducting commercial activities under a commercial license in the UAE.`,
          `Free zone businesses will be subject to Corporate Tax, with continued incentives for compliance with regulatory requirements and non-conduct of business in the UAE's mainland.`,
          `Foreign entities and individuals will be liable for Corporate Tax if they engage in ongoing or regular trade or business activities in the UAE.`,
          `Banking operations, businesses involved in real estate management, construction, development, agency, and brokerage activities will also fall under the ambit of Corporate Tax.`,
        ],
      },
      {
        mainHeading: `Corporate Tax FAQs:`,
      },
      {
        points: [
          `The Ministry of Finance has provided a comprehensive FAQ document to address key queries and concerns related to Corporate Tax. This resource is accessible at `,
        ],
      },
      {
        link: {
          text: `Corporate Tax FAQs.`,
          link: "https://mof.gov.ae/corporate-tax-faq/",
        },
      },
      {
        mainHeading: `Corporate Tax - Overview:`,
      },
      {
        points: [
          `Corporate Tax (CT) is a form of direct tax levied on the net income or profit of corporations and businesses, also known as "Corporate Income Tax" or "Business Profits Tax" in other jurisdictions.`,
          `The introduction of CT aligns with global standards and is expected to bolster the UAE's standing as a prominent global hub for business and investment.`,
        ],
      },
      {
        mainHeading: `Why Introduce Corporate Tax in the UAE:`,
      },
      {
        points: [
          `A competitive CT regime, aligned with international best practices, is anticipated to reinforce the UAE's position as a leading global business and investment destination.`,
          `The move signifies the UAE's commitment to meeting international standards for tax transparency and preventing harmful tax practices.`,
        ],
      },
      {
        mainHeading: `International Context:`,
      },
      {
        points: [
          `While the UAE is introducing Corporate Tax, it's important to note that most countries globally, including many in the Middle East, already have comprehensive Corporate Tax regimes.`,
        ],
      },
      {
        paragraph: `As the UAE undergoes this transformative change in its taxation landscape, AlKanaf Accountants stands ready to provide comprehensive Corporate Tax Advisory services. Our expert team ensures that businesses navigate this new terrain with clarity, compliance, and strategic financial planning. Embrace the future of taxation with AlKanaf Accountants as your trusted guide.`,
      },
    ],
  },
];