import React from "react";
import { makeStyles } from "@mui/styles";
import Introduction from "../Components/Introduction";
import Carousel from "../Components/Carousel";
import WhyChooseUs from "../Components/WhyChooseUs";
import Services from "../Components/Services";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "-webkit-fill-available",
    width: "100%",
    margin: "20px 0px 5px 0px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

function Home() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Introduction />

      <Carousel />

      <Services />

      <WhyChooseUs />
    </div>
  );
}

export default Home;
