import React, { useState } from "react";
import { Button, Modal, Box, TextField, Typography } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Alert from "@mui/material/Alert";
import emailjs from "emailjs-com";
import CircularProgress from "@mui/material/CircularProgress";


const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
const emailJS = process.env.REACT_APP_EMAILJS_KEY;

const ContactModal = ({
  isOpen,
  onClose,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    contact: "",
    email: "",
    message: "",
  });

    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [loading, setLoading] = useState(false);

  
  const handleModalClose = () => {
    setErrorMessage("");
    setSuccessMessage("");
    onClose();
  }

  const handleFormSubmit = async (formData) => {
    try {
      emailjs.init(emailJS);
      const templateParams = {
        to_name: "AlKanaf",
        from_name: formData.name,
        contact: formData.contact,
        email: formData.email,
        reply_to: formData.email,
        message: formData.message,
      };

      await emailjs.send(
        serviceId,
        templateId,
        templateParams
      );

    } catch (error) {
      console.error("Email failed to send:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    setErrorMessage("");
    setSuccessMessage("");
    e.preventDefault();
    setLoading(true);
    try {
      if (Object.values(formData).some((value) => !value.trim())) {
        setErrorMessage("Please fill all the fields with valid information");
        setSuccessMessage("");
        setLoading(false);
      } else {
        await handleFormSubmit(formData);
        setSuccessMessage("Message sent successfully");
        setErrorMessage("");
        setLoading(false);
        setFormData({
          name: "",
          contact: "",
          email: "",
          message: "",
        });
      }
    } catch (error) {
      setErrorMessage("Error sending message");
      setSuccessMessage("");
      setLoading(false);
    }
  };

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: 400,
          bgcolor: "white",
          p: 4,
          borderRadius: 2,
          overflow: "auto",
          boxShadow: "rgba(0,0,0,0.6)",
        }}
      >
        <CloseOutlinedIcon
          sx={{ position: "absolute", top: 20, right: 20, cursor: "pointer" }}
          onClick={handleModalClose}
        />
        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontWeight: 700, color: "rgba(0,0,0,0.7)" }}
        >
          Contact Us
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            margin="normal"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
          <TextField
            label="Contact"
            variant="outlined"
            fullWidth
            margin="normal"
            name="contact"
            value={formData.contact}
            onChange={handleChange}
          />
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
          <TextField
            label="Message"
            variant="outlined"
            fullWidth
            margin="normal"
            multiline
            rows={4}
            name="message"
            value={formData.message}
            onChange={handleChange}
          />
          <Box sx={{ marginTop: "20px", display: "grid" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
            >
              {loading ? (
                <>
                  <CircularProgress
                    size={24}
                    color="inherit"
                    style={{ marginRight: 8 }}
                  />
                  Sending...
                </>
              ) : (
                "Send"
              )}
            </Button>
          </Box>

          <Box sx={{ marginTop: "20px" }}>
            {successMessage && (
              <Alert severity="success">{successMessage}</Alert>
            )}
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default ContactModal;
