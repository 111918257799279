import React from "react";
import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import logo from "../Images/Al Kanaf_website.jpg";
import { navItems } from "./NavItems";
import { Link, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import DrawerMenu from "./DrawerMenu";

const drawerWidth = 240;

export default function DrawerAppBar() {

  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);
  const scrollToTop = (path) => {
    window.scrollTo(0, 0);
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleLogoClick = () => {
    navigate("/");
    scrollToTop();
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Avatar
            src={logo}
            onClick={handleLogoClick}
            sx={{
              width: "80px",
              height: "80px",
              margin: "10px 10px 10px 0px",
              cursor: "pointer",
            }}
          ></Avatar>
          <Box sx={{ cursor: "pointer" }} onClick={handleLogoClick}>
            <Typography
              variant="h3"
              component="div"
              sx={{ flexGrow: 1, display: { sm: "block" } }}
            >
              ALKANAF
            </Typography>
            <Typography
              variant="body2"
              component="div"
              sx={{
                flexGrow: 1,
                display: { xs: "block", sm: "block" },
                fontWeight: 500,
              }}
            >
              ACCOUNTING & AUDITING
            </Typography>
          </Box>

          <Box sx={{ display: { xs: "none", sm: "flex", marginLeft: "auto" } }}>
            {navItems.map((item, index) =>
              item.menu ? (
                <Menu navItem={item} key={index} />
              ) : (
                <Button
                  key={index}
                  sx={{ color: "#fff" }}
                  component={Link}
                  to={item.path}
                  onClick={scrollToTop}
                >
                  {item.title}
                </Button>
              )
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          <DrawerMenu handleDrawerToggle={handleDrawerToggle}></DrawerMenu>
        </Drawer>
      </nav>
    </Box>
  );
}
