export const auditServices = [
  {
    id: 1,
    title: "External Audit",
    description: [
      {
        paragraph: `At AlKanaf, we understand the importance of financial transparency and compliance in the dynamic business environment of the United Arab Emirates. Whether mandated by law or pursued for strategic advantages, our comprehensive financial services are designed to cater to the diverse needs of businesses across the region.`,
      },
      {
        mainHeading: `Mandatory Audits for UAE Entities:`,
      },
      {
        subHeading: `Legal Obligations:`,
      },
      {
        points: [
          `Most entities in the UAE are legally required to undergo annual financial statement audits.`,
        ],
      },
      {
        subHeading: `Strategic Decision-making`,
      },
      {
        points: [
          `Even for businesses not mandated by law, opting for an audit can offer strategic advantages. It becomes a valuable asset for obtaining the best price during business sales, ensuring financial institutions' confidence for securing finance, and maintaining positive relationships with credit insurers and suppliers.`,
        ],
      },
      {
        subHeading: `Internal Assurance`,
      },
      {
        points: [
          `Beyond compliance, audits provide a robust internal control mechanism, offering you the assurance of strong financial controls and accurate results.`,
        ],
      },
      {
        mainHeading: `International Standards Compliance:`,
      },
      {
        subHeading: `Stringent Standards:`,
      },
      {
        points: [
          `We adhere to the International Standards of Auditing to ensure that your audits meet global benchmarks.`,
        ],
      },
      {
        subHeading: `IFRS-Based Accounts:`,
      },
      {
        points: [
          `Our services extend to preparing accounts in accordance with the International Financial Reporting Standards, ensuring alignment with international accounting practices.`,
        ],
      },
      {
        mainHeading: `Tailored Solutions for Private Companies:`,
      },
      {
        subHeading: `Audit Alternatives:`,
      },
      {
        points: [
          `Recognizing that not all private companies necessitate full-scale audits, we offer alternative services such as reviews and compilations.`,
        ],
      },
      {
        subHeading: `Customized Approach:`,
      },
      {
        points: [
          `Our team works closely with clients to understand their unique reporting needs. We provide tailored advice on the best-suited alternative services that meet both regulatory requirements and the expectations of financial statement users.`,
        ],
      },
      {
        mainHeading: `Timely and Efficient Statutory Compliance:`,
      },
      {
        subHeading: `Efficient Processes:`,
      },
      {
        points: [
          `We collaborate with our clients to ensure the timely completion of audits, facilitating the filing of audited accounts in accordance with statutory obligations.`,
        ],
      },
      {
        subHeading: `Proactive Guidance:`,
      },
      {
        points: [
          `Our team offers proactive guidance throughout the auditing process, streamlining procedures to meet deadlines effectively.`,
        ],
      },
      {
        paragraph: `At AlKanaf, we pride ourselves on delivering professional financial services that not only fulfill legal obligations but also add strategic value to your business. Whether you require a mandatory audit, alternative review services, or tailored financial advice, we are your trusted partner in navigating the intricate landscape of financial compliance in the UAE.`,
      },
    ],
  },
  {
    id: 2,
    title: "Internal Audit",
    description: [
      {
        paragraph: `At AlKanaf, we recognize the pivotal role internal audits play in enhancing organizational efficiency, risk management, and governance procedures. Our dedicated team of auditors in Dubai and across the UAE is committed to providing unparalleled internal audit services designed to bring value to your business.`,
      },
      {
        mainHeading: `Understanding the Essence of Internal Audits:`,
      },
      {
        subHeading: `Strategic Importance`,
      },
      {
        points: [
          `Internal audits are not just compliance exercises; they are strategic tools aimed at ensuring the smooth functioning of operations.`,
        ],
      },
      {
        subHeading: `Systematic Assessment:`,
      },
      {
        points: [
          `Through systematic and administrative assessments, we enhance the efficacy of risk management, control, and governance procedures.`,
        ],
      },
      {
        subHeading: `Goal Alignment: `,
      },
      {
        points: [
          `The primary goal of internal auditing is to assist boards of directors and top executives in achieving organizational objectives by monitoring key aspects such as operating efficiency, risk management, and legal compliance.`,
        ],
      },
      {
        mainHeading: `Comprehensive Auditing Services:`,
      },
      {
        subHeading: `Diverse Areas Covered:`,
      },
      {
        points: [
          `Our Auditing Services in Dubai encompass various audit areas, including financial, performance, compliance, system security, and due diligence audits.`,
        ],
      },
      {
        subHeading: `Expert Auditors:`,
      },
      {
        points: [
          `Our team of excellent auditors at AlKanaf is responsible for meticulous planning and execution, ensuring a comprehensive evaluation of all internal audit-related services.`,
        ],
      },
      {
        mainHeading: `Risk Assessment and Management:`,
      },
      {
        subHeading: `Daily Imperative: `,
      },
      {
        points: [
          `We understand that assessing and managing risk is a continuous process that demands a proactive approach.`,
        ],
      },
      {
        subHeading: `Top-Down Approach:`,
      },
      {
        points: [
          `Our internal audit services follow a top-down, risk-based approach to identify and manage potential risks effectively.`,
        ],
      },
      {
        mainHeading: `Tailored Internal Audit Solutions:`,
      },
      {
        subHeading: `Right-Sized Services:`,
      },
      {
        points: [
          `Whether you require assistance for your existing internal audit function or a turn-key solution, our services are tailored to the specific needs of your organization.`,
        ],
      },
      {
        subHeading: `Proactive Strategies: `,
      },
      {
        points: [
          `AlKanaf Accounting & Auditing, within the AlKanaf network, conducts audits in a pro, forward-thinking manner, viewing audits not just as traditional advisory services but as tools for future success.`,
        ],
      },
      {
        mainHeading: `Benefits of Internal Audits:`,
      },
      {
        subHeading: `Risk Identification: `,
      },
      {
        points: [
          `Clients can identify and manage risks effectively, fostering a proactive risk management culture.`,
        ],
      },
      {
        subHeading: `Operational Efficiency:`,
      },
      {
        points: [
          `Internal audits add value and efficiency to operations, ensuring that processes run smoothly at all times.`,
        ],
      },
      {
        subHeading: `Strategic Decision-Making:`,
      },
      {
        points: [
          `The insights gained from audits contribute to the development of successful business strategies and smarter decision-making.`,
        ],
      },
      {
        subHeading: `Asset Safeguarding:`,
      },
      {
        points: [
          `Our audits play a crucial role in safeguarding assets, providing assurance on the robustness of internal controls.`,
        ],
      },
      {
        paragraph: `At AlKanaf Accounting & Auditing, we place a premium on honesty and reliability, offering internal audit services that go beyond mere compliance, acting as catalysts for organizational excellence and transformation.`,
      },
    ],
  },
  {
    id: 3,
    title: "Cost Audit",
    description: [
      {
        paragraph: `At AlKanaf Accounting & Auditing, we understand that efficient cost management is fundamental to sustainable business success. Our dedicated team of experts in Dubai and across the UAE is pleased to offer unparalleled Cost Audit Services tailored to meet the unique needs of your organization.`,
      },
      {
        mainHeading: ` Strategic Importance of Cost Audits::`,
      },
      {
        subHeading: `Enhancing Financial Health: `,
      },
      {
        points: [
          `Cost audits go beyond traditional financial assessments, focusing on the detailed examination of costs and expenditures to enhance overall financial health.`,
        ],
      },
      {
        subHeading: `Operational Efficiency: `,
      },
      {
        points: [
          `By scrutinizing cost structures, we help businesses identify areas for improvement, driving operational efficiency and maximizing profitability.`,
        ],
      },
      {
        mainHeading: `Comprehensive Cost Audit Services:`,
      },
      {
        subHeading: `Diverse Industry Coverage:`,
      },
      {
        points: [
          `Our Cost Audit Services in Dubai cater to a wide range of industries, ensuring that our clients receive customized solutions relevant to their specific sector.`,
        ],
      },
      {
        subHeading: `Experienced Professionals: `,
      },
      {
        points: [
          `Our team of experienced professionals at AlKanaf is dedicated to conducting thorough and accurate cost audits, providing valuable insights for informed decision-making.`,
        ],
      },
      {
        mainHeading: `Strategic Cost Management:`,
      },
      {
        subHeading: `Proactive Cost Control:`,
      },
      {
        points: [
          `We collaborate with businesses to implement proactive cost control measures, helping organizations stay competitive in dynamic market conditions.`,
        ],
      },
      {
        subHeading: `Risk Mitigation: `,
      },
      {
        points: [
          ` Through detailed cost analysis, we identify potential risks and recommend strategies to mitigate them, contributing to long-term financial sustainability.`,
        ],
      },
      {
        mainHeading: `Tailored Cost Audit Solutions:`,
      },
      {
        subHeading: `Industry-Specific Approaches:`,
      },
      {
        points: [
          `Our Cost Audit Services are designed with a keen understanding of industry-specific challenges, ensuring that our solutions are both relevant and effective.`,
        ],
      },
      {
        subHeading: `Flexible Engagement Models: `,
      },
      {
        points: [
          `AlKanaf Accounting & Auditing offers flexible engagement models, accommodating the diverse needs of businesses seeking cost audit services in Dubai and the UAE.`,
        ],
      },
      {
        mainHeading: ` Why Choose AlKanaf for Cost Audit Services:`,
      },
      {
        subHeading: `Expertise: `,
      },
      {
        points: [
          `Our team brings extensive expertise in cost audit methodologies, ensuring a comprehensive and accurate assessment.`,
        ],
      },
      {
        subHeading: `Client-Centric Approach:`,
      },
      {
        points: [
          `We prioritize a client-centric approach, tailoring our cost audit services to address the specific challenges and objectives of each business.`,
        ],
      },
      {
        subHeading: `Commitment to Excellence:`,
      },
      {
        points: [
          `AlKanaf Accounting & Auditing is committed to excellence in providing cost audit services that contribute to the financial health and sustainability of your organization.`,
        ],
      },
      {
        paragraph: `Choose AlKanaf Accounting & Auditing for cost audit services that transcend conventional financial assessments, offering strategic insights to optimize costs and drive sustainable business growth in Dubai and across the UAE.`,
      },
    ],
  },
];
