import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import PageLayout from "./Components/PageLayout";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import NotFoundPage from "./Pages/NotFoundPage";
import AccountingService from "./Pages/AccountingService";
import AuditService from "./Pages/AuditService";
import TaxService from "./Pages/TaxService";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";

const theme = createTheme({
  typography: {
    h2: { fontSize: "5rem" },
  },
  breakpoints: {
    values: {
      // xs: 0,
      // sm: 425,
      // md: 768,
      // lg: 1024,
      // xl: 1280,
      // "2xl": 1536,
      // "3xl": 1920,
      // "4xl": 2560,
      // "5xl": 3200,

      // xs: 0, // Extra small devices (phones, less than 600px)
      // sm: 960, // Small devices (laptops, 960px and up)
      // md: 1280, // Medium devices (larger laptops, 1280px and up)
      // lg: 1920, // Large devices (desktops, 1920px and up)
      // xl: 2560, // Extra large devices (large desktops, 2560px and up)

      // xs: 0, // Extra small devices (phones, less than 600px)
      // sm: 960, // Small devices (laptops, 960px and up)
      // md: 1280, // Medium devices (larger laptops, 1280px and up)
      // lg: 1920, // Large devices (desktops, 1920px and up)
      // xl: 2560, // Extra large devices (large desktops, 2560px and up)

      xs: 0, // Extra small devices (phones, less than 600px)
      sm: 900, // Small devices (laptops and big devices, 600px and up)
      md: 960, // Medium devices (iPads and tablets, 960px and up)
      lg: 1280, // Large devices (desktops, 1280px and up)
      xl: 1920, // Extra large devices (large desktops, 1920px and up)
    },
  },
});

// Apply responsive font sizes to the theme
const responsiveTheme = responsiveFontSizes(theme, {
  breakpoints: ["xs", "sm", "md", "lg", "xl", "2xl", "3xl", "4xl", "5xl"],
  factor: 5,
});

function App() {
  return (
    <ThemeProvider theme={responsiveTheme}>
      <Router>
        <Routes>
          <Route element={<PageLayout />}>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/accounting" element={<AccountingService />} />
            <Route path="/audit" element={<AuditService />} />
            <Route path="/tax" element={<TaxService />} />
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
