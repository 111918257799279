import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";


const ScrollToTopButton = ({style}) => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setShowButton(scrollTop > 500);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 300,
      behavior: "smooth",
    });
  };

  return (
    <Button
      color="primary"
      variant="contained"
      onClick={handleScrollToTop}
      style={{ display: showButton ? "flex" : "none", position: "fixed", ...style }}
    >
      <KeyboardDoubleArrowUpIcon sx={{fontSize:"30px"}}/>
    </Button>
  );
};

export default ScrollToTopButton;
